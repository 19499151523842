@use 'sass:list';
@use '../utils/z-index';

.user-balance {
	.user-balance__title {
		font: var(--label-regular);
	}

	.user-balance__sum {
		font: var(--h2);
	}
}

.deposit,
.withdrawal {
	&.menu--is-open,
	&.deposit--open,
	&.withdrawal--open {
		z-index: list.index(z-index.$elements, modal-view);

		~ .account-menu {
			opacity: 0;
			transition: 250ms 250ms;
		}
	}
}

.last-login-timer {
	font: var(--caption);
	position: relative;
	text-align: center;
	top: 10px;
}

.session-timer {
	font: var(--small);
	text-align: center;

	&.danish-session-timer--regulation-header {
		font: var(--label-bold);
	}
}

.legacy-withdrawal.withdrawal.reg-header-enabled {
	height: calc(100% - var(--header-height));
	top: var(--header-height);
}

.legacy-withdrawal.withdrawal .withdrawal-step {
	padding-top: var(--header-height);
}

.js-multichoice-question.notify--is-open.reg-header-enabled {
	height: calc(100% - var(--header-height));
	top: var(--header-height);
}

.account-menu {
	.btn--secondary {
		font: var(--label-regular);
	}
}
