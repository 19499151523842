.form {
	fieldset {
		margin: 0 0 6px;
	}

	.grow-wrap:focus-within {
		outline: none;

		~ .form__border {
			transform: translateY(0) scale(1);
		}
	}

	textarea {
		border-radius: 3px 3px 0 0;
		color: rgba(var(--input-font-color));
		font: var(--heading-5);
		padding: 22px 12px 9px;
		width: 100%;

		+ label,
		+ legend {
			font: var(--heading-5);
			left: 12px;
			position: absolute;
			top: 10px;
			transform-origin: left;
			transition: transform 250ms ease-out;
		}

		&:placeholder-shown {
			+ label {
				opacity: 1;
				transform: translateY(10px);
			}
		}

		&:not(:placeholder-shown) {
			+ label {
				opacity: 1;
				transform: translateY(-10px) scale(0.7);
			}
		}
	}

	textarea {
		+ label {
			transform: translateY(-50%);
		}
	}

	.error {
		display: block;
		font: var(--caption);
		min-height: 20px;
		padding: 0 0 0 12px;
	}
}
