@use '../utils/mixins';

.details-accordion {
	position: relative;

	summary::-webkit-details-marker {
		display: none;
	}

	summary {
		font: var(--label-regular);
		outline: none;
		text-align: left;
	}

	.summary-icon {
		position: absolute;
		right: 20px;
		top: 26px;
	}

	.summary-icon::after {
		@include mixins.size(24px);

		background-image: url('../../img/svg/bottom.svg');
		background-repeat: no-repeat;
		bottom: 6px;
		content: '';
		filter: brightness(1) sepia(1) hue-rotate(0deg) saturate(1);
		pointer-events: none;
		position: absolute;
		z-index: 2;
	}
}

.details-accordion[open] summary ~ * {
	animation: opacity 250ms ease-in-out;
	opacity: 1;
}

@keyframes opacity {
	0% {
		opacity: 0;
		transform: translate(0, -30%);
	}

	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}
