@use '../utils/mixins';
@use '../utils/z-index';

.modal {
	@include mixins.size(100%);

	left: 0;
	position: absolute;
	top: 0;
	transition: 250ms 500ms ease-in;
	transition: transform 450ms;
	z-index: index(z-index.$elements, modal-view);

	&.modal--enter {
		transform: translate3d(0, 100vh, 0);

		&.modal--enter-active {
			transform: translate3d(0, 0, 0);
		}
	}

	&.modal--exit {
		transform: translate3d(0, 0, 0);

		&.modal--exit-active {
			transform: translate3d(0, 100vh, 0);
		}
	}
}

.modal-wrapper {
	.modal-space--large--horizontal {
		@include mixins.breakpoint(lg) {
			padding-left: var(--largerModalPaddingHorizontal);
			padding-right: var(--largerModalPaddingHorizontal);
		}
	}

	.modal-space--large--vertical {
		@include mixins.breakpoint(lg) {
			padding-bottom: var(--largerModalPaddingVertical);
			padding-top: var(--largerModalPaddingVertical);
		}
	}
}
