@use 'sass:map';
@use 'sass:meta';
// MEDIA QUERIES

@mixin mq($args...) {
	$media-type: 'only screen';
	$media-type-key: 'media-type';
	$args: meta.keywords($args);
	$expr: '';

	@if map.has-key($args, $media-type-key) {
		$media-type: map.get($args, $media-type-key);
		$args: map.remove($args, $media-type-key);
	}

	@each $key, $value in $args {
		@if $value {
			$expr: '#{$expr} and (#{$key}: #{$value})';
		}
	}

	@media #{$media-type} #{$expr} {
		@content;
	}
}

// BREAKPOINTS

@mixin breakpoint($point: 'md', $orientation: false) {
	$min: false;
	$max: false;
	@if $point == 'sm' {
		// 320px
		$min: 20em;
	} @else if $point == 'md' {
		// 375px
		$min: 23.4375em;
	} @else if $point == 'lg' {
		// 768px
		$min: 48em;
	} @else if $point == 'xl' {
		// 1028px
		$min: 64.25em;
	} @else if $point == 'xxl' {
		// 1750px
		$min: 109.375em;
	}
	@include mq($min-width: $min, $max-width: $max, $orientation: $orientation) {
		@content;
	}
}

// SIZE

@mixin size($width, $height: $width) {
	height: $height;

	/* If height is omitted, it falls back to the width value */
	width: $width;
}
