// Used when loading Log In & Sign Up view

@keyframes bounceOutRight {
	20% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	100% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}

// Used when loading Log In & Sign Up view

@keyframes slideIn {
	0% {
		opacity: 0;
		transform: translateX(100%);
		visibility: hidden;
	}

	100% {
		opacity: 1;
		transform: translateX(0);
		visibility: visible;
	}
}
