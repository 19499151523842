@use '../utils/mixins';

.form {
	fieldset {
		margin: 0 0 6px;
	}

	input[type='text'],
	input[type='date'],
	input[type='time'],
	input[type='number'],
	select,
	.custom-select select {
		border-radius: 3px 3px 0 0;
		font: var(--heading-5);
		font-size: 16px;
		padding: 24px 12px 8px;
		width: 100%;

		+ label,
		+ legend {
			font: var(--heading-5);
			font-size: 16px;
			left: 12px;
			position: absolute;
			top: 10px;
			transform-origin: left;
			transition: transform 250ms ease-out;
		}

		&:focus,
		&:valid {
			outline: none;

			~ .form__border {
				transform: translateY(0) scale(1);
			}
		}

		&:placeholder-shown {
			+ label {
				opacity: 1;
				transform: translateY(6px);
			}
		}

		&:not(:placeholder-shown),
		&:focus {
			+ label {
				opacity: 1;
				transform: translateY(-6px) scale(0.7);
			}
		}

		&.input-amount {
			border-radius: 3px 3px 0 0;
			font: var(--h2);
			height: 64px;
			max-height: 64px;
			padding-top: 14px;
			text-align: center;

			&:placeholder-shown {
				font: var(--heading-5);
				font-size: 16px;
				text-align: left;
			}

			+ label {
				font: var(--h2);
				right: 12px;
				text-align: center;
				top: 50%;
				transform-origin: center;
				visibility: hidden;
			}
		}
	}

	.custom-select::after {
		right: 6px;
		z-index: 0;
	}

	input[type='date']::-webkit-calendar-picker-indicator,
	input[type='time']::-webkit-calendar-picker-indicator {
		margin-top: -12px;
	}

	.form__border {
		bottom: 1px;
		height: 1px;
		left: 0;
		margin: 0 auto;
		position: relative;
		right: 0;
		transform: translateY(1px) scale(0);
		transform-origin: top center;
		transition: transform 250ms ease-in, background-color 250ms ease-in;

		&.form__border--error {
			transform: translateY(0) scale(1);
		}
	}

	.edit-wespin-buyin .form__border {
		transform: translateY(0) scale(1);
	}

	input[type='date'],
	input[type='time'] {
		height: 56px;

		+ label {
			transform: translateY(-140%) scale(0.75);
			transform-origin: left;
		}
	}

	.two-inputs {
		fieldset:first-child {
			margin-right: 6px;
		}
	}

	.error {
		display: block;
		font: var(--caption);
		min-height: 20px;
		padding: 0 0 0 12px;
	}
}

/* This is hacky way to detect when webkit browsers fex chrome, auto fill input fields
We add animation on a webkit pseudo class that is added on auto fill.
Read more on https://stackoverflow.com/a/41530164 */

@keyframes onAutoFillStart {
	from {
		/* empty keyframes, only added to trigger js event */
	}

	to {
		/* empty keyframes, only added to trigger js event */
	}
}

@keyframes onAutoFillCancel {
	from {
		/* empty keyframes, only added to trigger js event */
	}

	to {
		/* empty keyframes, only added to trigger js event */
	}
}

.input-wrap {
	flex: 1;
	overflow: hidden;

	.input-wrap__input {
		background-color: rgba(var(--input-background-color), 1);
		border-radius: 3px 3px 0 0;
		font: var(--heading-5);
		font-size: 16px;
		padding: 24px 12px 8px;
		width: 100%;

		&:focus {
			outline: none;
		}

		&:placeholder-shown {
			padding: 16px 8px;

			~ .country-code-sign {
				top: 16px;
			}
		}

		&:disabled {
			cursor: not-allowed;
			opacity: 0.3;

			~ .country-code-sign {
				opacity: 0.3;
			}
		}

		&.input-wrap__input--large {
			border-bottom: 0;
			border-radius: 0;
			box-shadow: none;
			font: var(--display);
			letter-spacing: -2.1px;
			max-height: none;
			padding: 0;
			text-align: center;

			&:placeholder-shown,
			&:focus {
				border: 0;
				box-shadow: none;
			}
		}

		&:-webkit-autofill {
			animation-name: onAutoFillStart;
			transition: background-color 50000s ease-in-out 0s;
		}

		&:not(:-webkit-autofill) {
			animation-name: onAutoFillCancel;
		}

		&--always-focused {
			background-color: red;
		}

		&--empty {
			padding: 16px 12px;
		}
	}

	&.input-wrap--small {
		max-width: 100px;
	}

	.input-wrap__error {
		border-bottom-color: rgba(var(--error), 1);

		&:focus {
			border-bottom-color: rgba(var(--error), 1);
		}
	}

	.country-code-sign {
		font: var(--heading-5);
		left: 12px;
		top: 24px;
	}

	&__label {
		font: var(--heading-5);
		left: 12px;
		opacity: 0;
		position: absolute;
		top: 42px;
		transition: 250ms ease-out;
		visibility: hidden;

		&--show {
			font: var(--caption);
			left: 12px;
			opacity: 1;
			top: 8px;
			visibility: visible;
		}
	}

	&__error-display {
		color: rgba(var(--error), 1);
		display: block;
		font: var(--caption);
		padding: 6px 0;

		&:empty {
			padding: 0;
		}
	}

	/* Limits range input styles */

	.slider-input {
		border-radius: 3px 3px 0 0;
		font: var(--h1);
		line-height: 37px;
		padding: 12px 0;
		text-align: center;
	}

	&.input-wrap__error .slider-input {
		color: rgba(var(--error), 1);

		&:focus {
			color: rgba(var(--error), 1);
		}
	}
}

.input-wrap-small {
	max-width: 100px;
}

/* Limits range input styles */

.slider-container {
	margin: 24px 0;

	.slider-component {
		padding-top: 9px;
	}
}

.input-field-title {
	font: var(--label-regular);
	text-align: center;
}

.deposit-limits-edit__button {
	color: rgba(var(--primary), 1);
}

.valid-limits-icon {
	right: 10px;
	top: 15px;
}

.toggle-password {
	bottom: auto;
	height: 56px;
	outline: none;
	right: 0;
	top: 0;

	~ .input-wrap__input {
		padding-right: 50px;
	}
}

.legend {
	font: var(--caption);
	top: 8px;
}

.custom-checkbox-list {
	li {
		padding: 12px 0;
	}

	li,
	label {
		cursor: pointer;
	}

	.custom-checkbox__right {
		svg {
			right: 0;
		}
	}
}

.radio-box > .custom-checkbox-list {
	margin-top: 0;
}

.custom-checkbox {
	/* Checkbox Icon to the left of label */
	position: relative;

	input[type='checkbox'] {
		left: -9999px;
		max-height: none;
		opacity: 0;
		position: absolute;
	}

	.custom-checkbox__label {
		display: block;
		font: var(--label-regular);
		transition: color 200ms ease;
	}

	.custom-checkbox__left {
		padding: 0 0 10px 28px;

		svg {
			left: 0;
			top: -2px;
		}
	}

	.custom-checkbox__auto_withdraw {
		font: var(--caption);
	}
}

.custom {
	position: relative;

	.custom__input {
		left: -9999px;
		max-height: none;
		opacity: 0;
		position: absolute;
	}

	.custom__label {
		display: block;
		font: var(--label-regular);
		margin-left: 24px;

		&::before,
		&::after {
			content: '';
			left: 0;
			position: absolute;
			right: auto;
		}
	}

	&.custom--radio {
		.custom__label {
			&::before,
			&::after {
				background: none;
				border-radius: 50%;
			}

			&::before {
				@include mixins.size(18px);

				border: 2px solid rgba(var(--custom-radio-border, var(--primary)), 1);
				top: 0;
			}

			&.custom-right-radio {
				left: auto;
				margin-left: 0;
				margin-right: 18px;
				right: 0;

				&::before {
					left: auto;
					right: 0;
				}

				&::after {
					left: auto;
					right: -2px;
				}
			}
		}

		.custom__input:checked + .custom__label::before {
			border-color: rgba(var(--custom-checked-radio-border-color, var(--primary)), 1);
		}

		.custom__input:checked + .custom__label::after {
			@include mixins.size(10px);

			background: rgba(var(--custom-checked-radio-bg, var(--primary)), 1);
			left: 4px;
			right: auto;
			top: 4px;
		}

		.custom__input:checked + .custom__label.custom-right-radio::after {
			left: auto;
			right: 4px;
		}

		.custom__input:disabled + .custom__label::before {
			border-color: rgba(var(--custom-disabled-radio-border-color, var(--base-09)), 1);
		}

		.custom__input:checked:disabled + .custom__label::after {
			background: rgba(var(--custom-checked-disabled-radio-bg, var(--base-09)), 1);
		}

		.custom__input:checked:disabled + .custom__label::before {
			border-color: rgba(var(--custom-checked-disabled-radio-border-color, var(--base-09)), 1);
		}
	}

	&.custom--checkbox {
		.custom__label {
			// font: var(--label-regular);
			&::before {
				@include mixins.size(18px);

				border: 2px solid rgba(var(--custom-checkbox-border-color, var(--primary)), 1);
				border-radius: 3px;
			}

			&::after {
				display: none;
				left: 5px;
			}

			&.custom-checkbox__right::before,
			&.custom-checkbox__right::after {
				left: auto;
				right: 14px;
				top: 1px;
			}

			&.custom-checkbox__right {
				margin-left: 0;
			}
		}

		.custom__input:checked + .custom__label::after {
			@include mixins.size(8px, 14px);

			border-bottom: 2px solid rgba(var(--custom-checked-checkmark-color, var(--base-01)), 1);
			border-right: 2px solid rgba(var(--custom-checked-checkmark-color, var(--base-01)), 1);
			display: block;
			margin: 0 5px 0 0;
			top: 0;
			transform: rotate(45deg);
		}

		.custom__input:checked + .custom__label::before {
			background: rgba(var(--custom-checked-checkbox-bg, var(--primary)), 1);
			border-color: rgba(var(--custom-checked-checkbox-border-color, var(--primary)), 1);
		}

		.custom__input:checked:disabled + .custom__label::before {
			background: rgba(var(--custom-checked-disabled-checkbox-bg, var(--primary)), 1);
			border-color: rgba(var(--custom-checked-disabled-checkbox-border-color, var(--primary)), 1);
		}

		.custom__input:checked:disabled + .custom__label::after {
			border-color: rgba(var(--custom-checked-disabled-checkbox-checkmark, var(--base-01)), 1);
		}
	}
}

.signup__content,
.signup-basic,
.deposit--open,
.js-mail-verify-step1 {
	.custom--checkbox {
		padding: 0;

		.error::before {
			border-color: rgba(var(--error), 1);
		}
	}
}

.bankwire-wrap,
.entercash-wrap {
	.input-wrap__label {
		opacity: 1;
		top: 8px;
		visibility: visible;
	}

	.input-wrap__input:placeholder-shown + .input-wrap__label {
		opacity: 0;
		top: 0;
		visibility: hidden;
	}
}

.field-reference-code-with-copy {
	align-items: flex-end;
	display: flex;

	button.btn.btn-rounded.btn-rounded-small {
		height: fit-content;
	}
}

/* masked input styles */
span.input-wrap__masked {
	line-height: 1;

	span {
		color: #949494;
		height: 24px;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		z-index: 1;

		i {
			color: transparent;
			font-style: normal;
			opacity: 0;
			visibility: hidden;
		}
	}

	.masked,
	span {
		font: var(--heading-5);
		font-size: 16px;
		padding: 24px 12px 8px;
	}
}

.auto-withdraw-checkbox {
	.custom-checkbox__label {
		font: var(--caption);
		margin-left: 8px;
		margin-top: 2px;
		text-align: left;
	}

	.custom-checkbox {
		margin-top: 20px;
	}
}

// Custom Radio button
.custom-radio-toggle {
	.custom-radio-toggle-input {
		left: -9999px;
		max-height: none;
		opacity: 0;
		position: absolute;
	}

	.custom-radio-toggle-label {
		background-color: rgba(var(--base-10), 1);
		color: rgba(255, 255, 255, 100%);
		font-size: 17px;
		height: 52px;
		position: relative;
		text-align: center;
		width: 50%;
	}

	.custom-radio-toggle-label:hover {
		cursor: pointer;
	}

	.custom-radio-toggle-input:checked + .custom-radio-toggle-label {
		background-color: rgba(var(--primary), 1);
	}

	.custom-radio-toggle-label:first-of-type {
		border-radius: 3px 0 0 3px;
	}

	.custom-radio-toggle-label:last-of-type {
		border-radius: 0 3px 3px 0;
	}

	.custom-radio-toggle-fieldset {
		display: flex;
		text-align: center;
		width: 100%;
	}
}

.sow-custom-header {
	background-color: rgba(var(--my-notifications-header-bg-color), 1) !important;

	svg {
		fill: var(--my-notifications-back-button-color) !important;
	}

	.sow-contact-support-override {
		background: transparent !important;
		font-size: 13px !important;
		width: auto !important;
	}
}

.sow-form-name:disabled {
	background-color: rgba(var(--input-background-color), 0.4) !important;
	border-bottom-color: rgb(var(--input-border-color)) !important;
	color: rgba(var(--input-font-color), 0.5) !important;
	cursor: not-allowed;
}

.sow-form-name:disabled + label {
	color: rgba(var(--input-placeholder-color), 0.8);
}

.sow-section-wrapper {
	position: relative;
}

.sow-options-wrapper {
	background-color: rgba(var(--source-of-wealth-dropdown-bg), 0.7);
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	position: absolute;
	top: 0;
	width: 100%;
}

.sow-options {
	background-color: rgba(var(--source-of-wealth-dropdown-bg), 1);
	display: flex;
	flex-direction: column;
}

.sow-dropdown-cta-button {
	align-items: center;
	border-bottom: 1px solid rgba(var(--source-of-wealth-dropdown-border), 1);
	border-top: 1px solid rgba(var(--source-of-wealth-dropdown-border), 1);
	display: flex;
	justify-content: space-between;

	button {
		padding: 12px;
	}
}

.sow-dropdown-buttons-wrapper {
	display: flex;
	flex: 0 0 400px;
	flex-direction: column;
	overflow-y: scroll;
	padding: 0 12px 40px;
}

.sow-custom-dropdown {
	.sow-chevron-icon {
		transform: rotate(180deg);
	}

	input[type='file'] {
		@include mixins.size(0.1px);

		opacity: 0;
		position: absolute;
	}

	.sow-hidden-input {
		align-items: center;
		border-bottom: 1px solid rgb(var(--input-border-color));
		color: rgba(var(--source-of-wealth-form-text), 1);
		cursor: pointer;
		display: flex;
		font-size: 14px;
		font-weight: bold;
		line-height: 22px;
		min-height: 56px;
		padding: 10px 0;

		&.disabled {
			cursor: not-allowed;
			opacity: 0.3;
		}

		&.not-disabled {
			&.no-files-chosen {
				&:hover {
					border-bottom-color: rgb(var(--input-border-hover-color));
				}
			}
		}

		&.no-files-chosen {
			background-color: rgba(var(--source-of-wealth-form-bg), 1);
			color: rgb(var(--input-placeholder-color));
			font: var(--heading-5);
			font-size: 16px;
			padding: 0 10px;
		}

		.uploaded-file-names-wrapper {
			display: flex;
			flex: 1;
			flex-wrap: wrap;

			> span {
				font: var(--heading-5);
				margin-right: 5px;
			}
		}

		.reviewing-text {
			font-size: 12px;
			opacity: 0.5;
		}
	}

	.sow-upload-helptext {
		color: rgba(var(--source-of-wealth-help-text), 1);
		font-size: 12px;
		margin-top: 5px;
	}

	.sow-current-date {
		color: rgba(var(--source-of-wealth-help-text), 1);
		font-size: 12px;
		margin-bottom: 5px;
	}
}

.subscription-checklist-custom {
	margin-top: 30px;

	.account-subscription-item {
		label {
			font-weight: bold;
		}

		&:disabled {
			opacity: 0.5;

			label {
				cursor: not-allowed;
			}
		}
	}
}
