@use 'sass:list';
@use 'mixins';
@use 'z-index';

.click-effect {
	&::after {
		@include mixins.size(42px);

		animation: circle 500ms forwards;
		background: rgba(var(--secondary), 0.25);
		border-radius: 50%;
		content: '';
		cursor: pointer;
		left: 50%;
		margin: -20px 0 0 -20px;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 50%;
		z-index: list.index(z-index.$elements, effect);
	}

	&:focus {
		outline: none;
	}
}

@keyframes circle {
	0% {
		opacity: 1;
		transform: scale3d(0.1, 0.1, 1);
	}

	100% {
		opacity: 0;
		transform: scale3d(4, 4, 1);
		visibility: hidden;
	}
}
