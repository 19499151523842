@use '../utils/mixins';

.custom-select-fieldset {
	flex: 1;
}

.custom-select {
	flex: 1;
	position: relative;

	legend {
		left: 12px;
	}

	select {
		appearance: none;
		background: none;
		border: 0;
		font: var(--heading-5);
		font-size: 16px;
		margin: 0;
		outline: none;
		padding: 24px 26px 8px 12px;
		width: 100%;

		&:active {
			color: inherit;
		}

		&:focus {
			color: inherit;
			outline: none;
		}
	}

	&.custom-select--small {
		max-width: 23vw;
	}

	&.custom-select--disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}

	.js-input-error-display {
		color: rgba(var(--error), 1);
		position: absolute;
		top: 60px;
	}

	&.disabled {
		&::after {
			content: none;
		}
	}
}

/* ------------------------------------  */

/* START OF UGLY BROWSER-SPECIFIC HACKS */

/* ----------------------------------  */

/*
IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance, IE 9 and earlier
get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 -
looking for better ways to achieve this targeting. The second rule removes the odd blue bg color behind the
text in the select button in IE 10/11 and sets the text color to match the focus style's - fix
via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box
*/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.custom-select select::-ms-expand {
		display: none;
	}

	.custom-select select:focus::-ms-value {
		background: transparent;
		color: #222;
	}
}

/*
Firefox focus has odd artifacts around the text, this kills that. See
https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring
*/

.custom-select select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}
