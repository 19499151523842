// Fix colors in storybook docs grid
.docblock-argstable-head,
.docblock-argstable-body {
	span {
		color: white;
	}
}

.docs-story #input-wrapper-storybook {
	background-color: unset;
	height: 150px;
	width: 375px;
}
