.welcome-menu {
	background: rgba(var(--primary), 1);
	flex: 1;

	.welcome__main-title {
		color: rgba(var(--base-02), 1);
		font: var(--h2);
	}

	.welcome__p {
		color: rgba(var(--base-02), 1);
		font: var(--label-regular);
	}

	.title-label {
		color: rgba(var(--base-02), 1);
		font: var(--h3);
	}

	.accordion {
		.accordion__link {
			outline: none;
		}

		.accordion__content {
			//TODO: fix this, reduce nesting REV-318
			//stylelint-disable-next-line selector-max-compound-selectors
			td {
				border-bottom: 0;
				padding: 13px 0 11px;
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				button {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors, max-nesting-depth
					span {
						position: relative;
						top: 2px;
					}
				}
			}

			tr:last-child {
				border-bottom: 0;
			}
		}
	}
}

.info-text {
	color: rgba(var(--base-02), 1);
	font: var(--caption);
}
