@use '../utils/mixins';

.menu {
	.link-list {
		&:first-of-type {
			margin-top: 8px;
		}
	}
}

.link-list {
	margin-bottom: 12px;

	.menu__main-title {
		font: var(--label-bold);
	}

	.bubble .icon--sprite {
		margin-left: 0;
	}

	&.link-list--large {
		.menu__main-title {
			font: var(--h4-bold);
		}
	}
}

.link-list__item {
	cursor: pointer;
	font: var(--label-bold);
	margin-bottom: 1px;

	&.disabled button {
		cursor: default;
		opacity: 0.5;
	}

	.link-list__item-link {
		align-items: center;
		display: flex;
		min-height: 52px;
		padding: 12px 12px 14px;
		width: 100%;
		word-break: break-word;

		svg,
		img,
		.link-list__continue-icon {
			margin: 0 8px 0 0;
		}

		.link-list__continue-icon {
			margin: 0 0 0 auto;
		}

		&.change-button {
			padding: 14px 4px 14px 12px;

			.link-list__method {
				flex-shrink: 0;
			}

			.link-list__value {
				display: flex;
				flex: 1;
				flex-direction: column;
				margin-left: 10px;
				text-align: right;
				white-space: break-spaces;

				span {
					color: rgba(var(--font-supportive), 1);
					font: var(--label-regular);
					margin: 0 4px;
				}
			}

			svg {
				flex-shrink: 0;
			}
		}
	}

	.link-list__value {
		margin: 0 0 0 auto;
		white-space: nowrap;

		span {
			margin-right: 4px;
		}
	}

	.change-method-link_link.link-list__value {
		flex-basis: 50%;
		white-space: break-spaces;

		.bank-name {
			display: flex;
			justify-content: flex-end;
		}
	}

	.link-list__value + .link-list__continue-icon {
		margin: 0;
	}

	span {
		font-family: inherit;
	}

	&:last-child {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	&:only-child {
		border-radius: 3px;
	}

	&:first-child {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	.link-list__status-message {
		padding-right: 8px;
	}
}

.link-list__preamble {
	color: inherit;
	margin: 2px 0;
	@include mixins.breakpoint(sm) {
		font: var(--small);
	}
	@include mixins.breakpoint(lg) {
		font: var(--caption);
	}
}

.list-options {
	.link-list__item {
		border-radius: 0;
		margin-bottom: 0;
		min-height: 44px;
	}

	.link-list__item-link {
		min-height: 44px;
		padding: 10px 0;
	}
}

.deposit-limit-link-list {
	.link-list__item {
		border-radius: 3px;
		margin-bottom: 12px;
	}
}
