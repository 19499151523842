html {
	box-sizing: border-box;
}

* {
	box-sizing: inherit;
	-webkit-tap-highlight-color: rgba(
		255,
		255,
		255,
		0%
	); // Setting default to zero, let's custom make this when/where we need it instead. /Martin

	-webkit-tap-highlight-color: transparent;

	&::before,
	&::after {
		box-sizing: inherit;
	}
}

textarea,
iframe {
	border: 0;
	display: block;
	width: 100%;
}

a {
	cursor: pointer;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}

button {
	appearance: none;
	background: transparent;
	border: 0;
	border-radius: 0;
	cursor: pointer;
	font-size: inherit;
	font-style: inherit;
	line-height: normal;
	padding: 0;
}

input,
fieldset {
	appearance: none;
	border: 0;
	font-family: inherit;
	margin: 0;
	min-width: 0; // inputs and fieldset defaults to having a min-width equal to its content in Chrome and Firefox
	padding: 0;
}

input {
	appearance: textfield;
	max-height: 62px;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		appearance: none;
	}
}

img {
	border: 0;
	display: block;
	height: 100%;
	object-fit: cover;
	width: 100%;
}

svg {
	display: block;
	fill: currentcolor;
	pointer-events: none;
	vertical-align: -0.15em; /* Nice visual alignment for icons alongside text */
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}
