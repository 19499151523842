@use '../utils/mixins';

.grid-u,
[class*='grid-u-'] {
	display: inline-block;
	letter-spacing: normal;
	vertical-align: top;
	word-spacing: normal;
	zoom: 1;
}

.grid-r {
	word-spacing: -0.34em;
}

.grid-u-sm-1-10 {
	width: 10%;
}

.grid-u-sm-1-4 {
	width: 25%;
}

.grid-u-sm-1-3 {
	width: 33.333%;
}

.grid-u-sm-1-2 {
	width: 50%;
}

.grid-u-sm-3-5 {
	width: 60%;
}

.grid-u-sm-2-3 {
	width: 66.666%;
}

.grid-u-sm-3-4 {
	width: 75%;
}

.grid-u-sm-4-5 {
	width: 80%;
}

.grid-u-sm-7-12 {
	width: 58.333%;
}

.grid-u-sm-3-12 {
	width: 25%;
}

.grid-u-sm-2-12 {
	width: 16.666%;
}

.grid-u-sm-1 {
	width: 100%;
}

@include mixins.breakpoint(lg) {
	.grid-u-lg-1-4 {
		width: 25%;
	}

	.grid-u-lg-1-3 {
		width: 33.3333%;
	}

	.grid-u-lg-1-2 {
		width: 50%;
	}

	.grid-u-lg-3-5 {
		width: 60%;
	}

	.grid-u-lg-3-4 {
		width: 75%;
	}
}

@include mixins.breakpoint(xl) {
	.grid-u-xl-1-12 {
		width: 8.333%;
	}

	.grid-u-xl-1-10 {
		width: 10%;
	}

	.grid-u-xl-1-6 {
		width: 16.666%;
	}

	.grid-u-xl-1-3 {
		width: 33.333%;
	}

	.grid-u-xl-2-3 {
		width: 66.666%;
	}
}
