// If CSS is exclusive to withdraw flow put it here, otherwise it should go under _payment.scss

// Pending Withdrawal

.pending-withdrawal {
	.pending-withdrawal__row {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	.pending-withdrawal__date {
		font: var(--caption);
	}

	.pending-withdrawal__action {
		.spinner {
			margin: 0 -18px;
		}
	}

	.pending-withdrawal__method {
		font: var(--label-bold);
	}

	.pending-withdrawal__amount {
		color: rgba(var(--font-supportive), 1);
		font: var(--h4-regular);
	}
}
