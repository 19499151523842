@use '../utils/mixins';
@use '../utils/z-index';

/* This file should be home to all CSS rules that are shared between deposit and withdraw */

.deposit,
.withdrawal {
	@include mixins.size(100%);

	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: 250ms 500ms ease-in;
	transition: transform 250ms ease-out;
	visibility: hidden;
	z-index: index(z-index.$elements, modal-view);

	.deposit-step,
	.withdrawal-step {
		@include mixins.size(100%, auto);

		height: calc(100% - var(--sidebarHeaderHeight));
		-webkit-overflow-scrolling: touch;

		&.slide-out-down {
			transform: translate3d(0, 100%, 0);
		}

		&.slide-in-up {
			transform: translate3d(0, 0, 0);
		}
	}

	&--open {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
}

.payment__label-amount {
	padding: 24px 12px;

	.payment__amount-info {
		font: var(--label-regular);

		&--small {
			font: var(--caption);
			height: 12px;
		}
	}

	&.input-wrap::after {
		content: none;
	}
}

.payment__form {
	.ReactCreditCard__logo {
		height: inherit;
		width: fit-content;
	}

	.ReactCreditCard {
		&__front {
			background-color: var(--credit-card);
			max-width: var(--credit-card-width);

			&::before {
				background-image: none;
			}
		}

		&__container {
			margin: 0 auto;
			max-height: var(--credit-card-height);
			// transform: scale(0.8);
			max-width: var(--credit-card-width);
		}

		&__number {
			color: inherit;
			font-size: 18px;
			margin-bottom: 12px;
			opacity: unset;
		}

		&__display {
			color: inherit;
		}

		&__expiration {
			font-size: 12px;
			opacity: unset;
		}

		&--visa .ReactCreditCard__logo {
			width: 65px;
		}
	}
}

.modal-view.bwi-modal {
	height: 101vh;
}

section.bwi-deposit-form {
	max-height: 90%;
}

.method-license-box {
	display: flex;
	justify-content: center;

	.method-license-text {
		bottom: 0;
		color: var(--filter-support-links, rgba(var(--base-01), 1));
		font-size: 13px;
		font-style: italic;
		font-weight: bold;
		position: absolute;
	}
}

.iframe-white-bg {
	background: white;
	padding: 10px;
}

.result-button-wrapper {
	background-color: var(--sidebar-bg, rgba(var(--base-11), 1));
}

.pending-withdraw-beta {
	height: calc(100% - var(--sidebarHeaderHeight));
}
