@use '../utils/z-index';
@use '../base/shared-variables';
@use '../utils/mixins';

$regHeaderTop: calc(var(--regulationHeight) + var(--headerHeight) + var(--subHeaderHeight));
$headerTop: calc(var(--headerHeight) + var(--subHeaderHeight));

.category-page {
	background: rgba(var(--category-page-bg), 1);
	height: 100vh;
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	top: 100%;
	transition: all 0.3s ease;
	width: 100vw;
	z-index: index(z-index.$elements, category-page);

	&::-webkit-scrollbar {
		display: none;
	}
}

.category-page--active {
	top: 0;
	@include mixins.breakpoint(lg) {
		height: calc(100vh - #{$headerTop});
		top: $headerTop;
	}
}

.category-page--regulation {
	height: calc(100vh - var(--regulationHeight));
	top: var(--regulationHeight);
	@include mixins.breakpoint(lg) {
		height: calc(100vh - #{$regHeaderTop});
		top: $regHeaderTop;
	}
}

.category-page__header {
	align-content: center;
	background: rgba(var(--category-page-bg), 1);
	display: grid;
	grid-template-columns: auto 1fr 30px;
	height: 45px;
	padding: 0 12px;
	position: sticky;
	top: 0;
	z-index: 2;
	@include mixins.breakpoint(lg) {
		padding: 0 24px;
	}
}

.category-page__exit-button {
	width: 30px;

	svg {
		fill: var(--category-exit-icon-color);
	}
}

.category-page__title {
	color: rgba(var(--category-page-title-color), 1);
	font: var(--h3);
	justify-self: center;
	@include mixins.breakpoint(lg) {
		justify-self: start;
	}
}

.category-page__list {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(96px, 1fr));
	padding: 5px 12px 100px;
	top: 45px;

	@include mixins.breakpoint(lg) {
		grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
		padding: 12px 24px 100px;
	}
}
