/* Adding the following two rules in order to hide
	 the seo-content before the main content is
	 loaded so the seo-content isn't the first thing
	 users see before anything else has loaded and
	 then the seo-content disappears (flickers)
*/
#main-layout > section.seo-content {
	display: none;
}

#main-layout > div + section.seo-content {
	display: block;
}

.seo-content {
	h1,
	h2,
	h3,
	h4 {
		padding: 12px 0;
	}

	h1 {
		font: var(--h1);
	}

	h2 {
		font: var(--h2);
	}

	ul {
		list-style-position: inside;
		list-style-type: disc;
	}

	ol {
		list-style-position: inside;
		list-style-type: decimal;
	}

	li {
		padding: 6px 0;
	}

	a {
		display: inline;
	}
}
