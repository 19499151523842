.icon-bottle-anim {
	width: 100%;

	path {
		fill: rgba(var(--base-02), 1);
	}
}

.icon-stars-anim {
	path {
		fill: rgba(var(--base-02), 1);
	}
}
