@use '../utils/mixins';

.reg-header-wrapper {
	.swipe-button-container {
		align-items: center;
		border-radius: 20px;
		display: flex;
		height: 28px;
		justify-content: center;
		overflow: hidden;
		position: relative;
		width: 100%;
		@include mixins.breakpoint(lg) {
			height: 40px;
		}
	}

	.swipe-button-slider {
		border-radius: 20px;
		float: left;
		height: inherit;
		left: -36px;
		position: absolute;
		top: 0;
		width: 100%;
		@include mixins.breakpoint(lg) {
			left: -40px;
		}
	}

	.panic-btn_icon {
		border-radius: 20px;
		cursor: grab;
		padding: 6px;
		position: absolute;
		right: 5px;
		top: 0;
		@include mixins.breakpoint(lg) {
			padding: 10px;
			right: 0;
		}

		.icon--sprite {
			height: 15px;
			width: 15px;
			@include mixins.breakpoint(lg) {
				height: 20px;
				width: 20px;
			}
		}
	}

	.panic-btn_txt {
		font: var(--caption-bold);

		/* margin-left: 18px; */
		@include mixins.breakpoint(lg) {
			font: var(--h3);
			margin-left: 38px;
		}
	}
}
