@use 'sass:list';
@use '../utils/z-index';

.app-banner {
	background-color: #e5e5ea;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	display: flex;
	height: var(--appbannerHeight);
	justify-content: space-between;
	padding: 12px;
	//position: absolute;
	//top: 0;
	width: 100%;
	z-index: list.index(z-index.$elements, app-banner);

	~ div .cookieConsent {
		z-index: 20;
	}

	.close-icon {
		color: #16161d;
		margin-right: 12px;
	}

	.info-wrapper {
		align-items: center;
		display: flex;
		height: 100%;

		p {
			color: #8e8e93;
			font-size: 12px;
			line-height: 1.33;
			margin: 4px;
			padding-left: 12px;
		}

		.title {
			color: #000;
			font-size: 15px;
			letter-spacing: -0.2px;
		}

		svg {
			border-radius: 14px;
		}
	}

	.button {
		color: #007aff;
		font-size: 15px;
		letter-spacing: -0.2px;
		line-height: 1.33;
		text-align: right;
	}
}
