@media (max-width: 1024px) {
	//stylelint-disable-next-line selector-id-pattern
	iframe#webWidget {
		bottom: 0 !important;
		top: auto !important;

		&.hasHeader {
			height: calc(100% - 44px) !important;
		}
	}
}
