.cc-form {
	.input-wrap {
		.input-wrap__input {
			border-radius: 0;
		}

		.month-input,
		.year-input,
		.cvv-input {
			padding: 24px 0 8px;

			&:placeholder-shown {
				padding: 16px 0;
			}
		}

		.month-input:not(:placeholder-shown) + .year-input {
			padding: 24px 0 8px;
		}

		.month-input:has(+ .year-input:not(:placeholder-shown)) {
			padding: 24px 0 8px;
		}

		&.expiry-wrap,
		&.cvv-wrap {
			.input-wrap__label {
				left: 0;
			}
		}
	}
}
