@use 'sass:list';
@use '../utils/z-index';

/* INFO: .regulations-header--spacer is "helper" element that takes care to push all view for
44px down. We need it as long as we have some elements rendered outside id="app" view.
In the moment of writing this info there are still some elements rendering in legacy and modal-view */

:root {
	--header-height: 44px;
}

.regulations-header {
	align-items: center;
	background-color: #d3d3d5;
	display: flex;
	height: var(--header-height);
	justify-content: center;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: list.index(z-index.$elements, regulations-header);

	~ div {
		.global-notification {
			top: var(--header-height);
		}
	}

	~ .notification-wrap,
	~ .page-head {
		top: var(--header-height);
	}

	~ .list-options,
	~ .log-in--open,
	~ .menu__content-wrapper,
	~ .risk-profile,
	~ .signup--open {
		padding-bottom: var(--header-height);
		top: var(--header-height);
	}

	~ .deposit,
	~ div .deposit,
	~ .menu,
	~ .modal-view,
	~ .notify,
	~ .result,
	~ .result.result--is-open,
	~ .shop-detail.shop-detail--open,
	~ .welcome-menu {
		height: calc(100% - var(--header-height));
		top: var(--header-height);
	}

	~ form {
		height: calc(100% - var(--header-height));
		padding-bottom: var(--header-height);
		top: var(--header-height);

		.modal-view {
			height: calc(100% - var(--header-height));
			padding-bottom: var(--header-height);
			top: var(--header-height);
		}
	}

	~ .games-by-bundle-container,
	~ .withdrawal {
		height: calc(100% - var(--header-height));
		padding-bottom: var(--header-height);
		top: var(--header-height);
	}
}

.regulations-header--spacer {
	background-color: transparent;
	display: block;
	height: 0;
	position: relative;
	top: 0;
	width: 100%;

	~ .deposit--open,
	~ .global-notification--is-open,
	~ .verify-email-menu,
	~ .verify-phone,
	~ .verify-email-result-menu,
	~ .withdrawal--open {
		height: calc(100% - var(--header-height));
		top: var(--header-height);
	}
}

.regulations-header__icon {
	filter: grayscale(100%);
	height: 20px;
}

.regulations-header__icon-support {
	position: absolute;
	right: 12px;
	top: 12px;

	svg {
		fill: #000;
		height: 20px;
	}
}

.regulations-header__button:nth-child(1) {
	margin: 0 2px 0 11px;
}

.regulations-header__button:nth-child(2) {
	margin-right: 14px;
}

.shop-detail--regulation {
	height: var(--header-height);

	~ .shop-grid .shop-detail {
		height: calc(100% - var(--header-height));
		top: var(--header-height);
	}
}

.deposit {
	.global-notification {
		&.payment-notification {
			top: 0;
		}
	}
}
