.terms-conditions {
	ul,
	ol {
		display: block;
		list-style-type: disc;
		padding: 12px 0 12px 12px;

		ul {
			margin-left: 12px;
		}

		li {
			display: list-item;
			margin-left: 12px;
		}
	}

	ol {
		list-style-type: decimal;
	}
}
