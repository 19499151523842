@use '../utils/mixins';

table {
	border-collapse: collapse;
	width: 100%;

	td {
		font: var(--label-regular);
		padding: 12px 0;
		text-align: left;

		small {
			white-space: nowrap;
		}

		a {
			font-size: inherit;
			text-decoration: underline;
		}

		span {
			color: inherit;
			font-family: inherit;
			font-size: inherit;
		}

		&:last-of-type {
			text-align: right;
		}
	}
}

.live-dot {
	&::after {
		@include mixins.size(9px);

		background-color: #f03;
		border-radius: 50%;
		content: '';
		display: inline-block;
		margin: auto 4px;
	}
}

.table--binary-question {
	td {
		border-bottom: 0;
		padding: 6px 0;
		text-align: left;

		&:last-of-type {
			text-align: right;
		}
	}
}

.u-p-small {
	td {
		padding: 6px 0;
	}
}
