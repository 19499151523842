@use '../utils/mixins';

.my-notifications-list-wrapper {
	margin-top: 28px;

	.my-notifications-allCategoryWrapper {
		border-bottom: 1px solid rgba(var(--my-notification-border-bottom, var(--unread-message-title-color)), 1);
		display: flex;
		-ms-overflow-style: none; /* IE and Edge */
		overflow-x: scroll;
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.my-notifications-individualCategory {
		color: rgba(
			var(--my-notification-category-color, var(--read-message-color)),
			1
		); //rgba(var(--read-message-color), 1);

		cursor: pointer;
		flex-shrink: 0;
		font-size: 14px;
		font-weight: normal;
		margin-right: 10px;
		padding: 10px 15px;

		&.my-notifications-has-unread {
			color: rgba(var(--unread-message-title-slider-not-active, var(--unread-message-title-color)), 1);
			font-weight: bold;
		}

		&.my-notifications-current-category {
			border-bottom: 2px solid rgba(var(--unread-message-title-color), 1);
			color: rgba(var(--unread-message-title-color), 1);
			font-weight: bold;
		}
	}
}

// Notifications List Item
.my-notification-item-wrapper {
	border-left: 4px solid;
	color: rgba(var(--unread-message-notifier-color), 1);
	margin-top: 12px;

	/*
	.my-notification-thumbnail {
		flex: 0 0 36px;
		border-radius: 3px;
		overflow: hidden;
		margin-top: 2px;
	}
	*/
	.my-notification-details {
		display: flex;
		flex: 1;
		flex-direction: column;
		padding-left: 12px;
		padding-right: 12px;
		text-align: left;

		.my-notification-title-bar {
			color: rgba(var(--unread-message-title-color), 1);
			display: flex;
			font: var(--label-bold);
			justify-content: space-between;
			margin-bottom: 4px;

			.my-notification-title {
				color: inherit;
				display: flex;
				flex: 1;
			}

			svg {
				width: 24px;
			}
		}

		.my-notification-subitle {
			color: rgba(var(--unread-message-subtitle-color), 1);
			/* stylelint-disable-next-line value-no-vendor-prefix */
			display: -webkit-box;
			font: var(--label-regular);
			margin-bottom: 4px;
			overflow: hidden;
			/* stylelint-disable-next-line order/properties-alphabetical-order */
			-webkit-line-clamp: 2;
			/* stylelint-disable-next-line order/properties-alphabetical-order */
			-webkit-box-orient: vertical;
		}

		.my-notification-time {
			color: rgba(var(--unread-message-date-color), 1);
			font: var(--caption);
		}
	}

	&.read {
		border-left-color: transparent;

		.my-notification-title-bar,
		.my-notification-subitle,
		.my-notification-time {
			color: rgba(var(--read-message-color), 1);
		}
	}
}

.notification-detail-container {
	.image-header-container {
		background-color: rgba(var(--my-notifications-header-bg-color), 1);
		height: 44px;
		width: 100%;
		// position: absolute;
	}

	.back {
		fill: var(--my-notifications-back-button-color) !important;
		height: 24px;
		left: 6px;
		position: absolute;
		top: 5px;
		width: 24px;
	}

	.detail-image {
		height: auto;
		object-fit: cover;
		object-position: center center;
	}

	.title {
		color: rgba(var(--unread-message-title-color), 1);
		font: var(--h2);
		overflow-wrap: break-word;
		padding: 12px 12px 8px;
	}

	.description {
		color: rgba(var(--unread-message-subtitle-color), 1);
		font: var(--label-regular);
		padding: 0 12px 8px;
		white-space: pre-line;
	}

	.data-list {
		padding: 14px 12px;

		.list-item {
			border-bottom: 1px solid;
			display: flex;
			height: 44px;

			:first-child {
				margin: 12px 0;
			}

			:last-child {
				margin: 12px 0 12px auto;
			}
		}
	}

	.notification-accordion-wrapper {
		border-radius: 3px;
		margin: 12px;

		p.notification-accordion-content {
			font: var(--caption);
			white-space: pre-line;
		}
	}
}

.my-notification-detail-CTA-button {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// Notifications: no content
.no-items-wrapper {
	padding: 28px 23px 0;
	text-align: center;

	svg {
		color: rgba(var(--no-notifications-icon-color), 1);
		height: 42px;
		margin: auto;
		width: 36px;
	}

	h1 {
		color: rgba(var(--no-notifications-heading-color), 1);
		margin-top: 14px;
	}

	small {
		color: rgba(var(--no-notifications-subheading-color), 1);
		font-size: 15px;
		margin-top: 7px;
	}

	a {
		border-radius: 26px;
		margin-top: 21px;
		padding: 8px 32px;
		width: auto;
	}
}

// Notifications: Header
.my-notifications-wrapper {
	.page-head {
		background-color: rgba(var(--my-notifications-header-bg-color), 1);

		h2 {
			color: rgba(var(--my-notifications-heading-color), 1);
		}

		button {
			color: rgba(var(--my-notifications-mark-all-read-color), 1);
		}

		svg {
			fill: var(--my-notifications-back-button-color);
		}
	}
}

.link-list-notification-color {
	align-items: center;
	background-color: rgba(var(--unread-message-counter-bg-color), 1);
	border-radius: 10px;
	color: rgba(var(--unread-message-counter-text-color), 1) !important;
	display: flex;
	font: var(--small-bold) !important;
	height: 20px;
	justify-content: center;
	width: 20px;
}

.my-notification-popup-back-button {
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-moz-transform: rotate(180deg);
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-transform: rotate(180deg);
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-o-transform: rotate(180deg);
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.my-notification-slider-item-container {
	align-items: center;
	background-color: rgba(var(--notification-popup-bg), 1);
	border-radius: 12px;
	height: 100%;
	justify-items: center;
	max-width: 'auto';
	position: relative;
	transform-style: preserve-3d;
	/* stylelint-disable-next-line order/properties-alphabetical-order */
	-webkit-overflow-scrolling: touch;
	width: 100%;

	&.isFlipped {
		.my-notification-slider-item-front {
			transform: rotateY(180deg);
		}

		.my-notification-slider-item-back {
			transform: rotateY(0deg);
		}
	}

	.my-notification-slider-item-front,
	.my-notification-slider-item-back {
		backface-visibility: hidden;
		background: rgba(var(--notification-popup-bg), 1);
		border-radius: 12px;
		display: block;
		height: 100%;
		min-height: 475px;
		padding: 10px;
		position: absolute;
		transform-style: preserve-3d;
		transition: transform linear 100ms;
		transition: -webkit-transform linear 100ms;
		width: 100%;

		.my-notification-slider-navigation-dots-wrapper {
			bottom: 0;
			left: 0;
			margin-bottom: 16px;
			position: absolute;
			width: 100%;
		}
	}

	.my-notification-slider-item-front {
		transform: rotateY(0deg);
		z-index: 2;
	}

	.my-notification-slider-additional-data-button {
		color: rgb(var(--embed-link-color));
		font-size: inherit;
	}

	.my-notification-slider-item-back {
		background: rgba(var(--notification-popup-bg), 1);
		transform: rotateY(-180deg);

		.my-notification-slider-item-back-icon {
			transform: scale(-1, -1);
		}
	}
}
