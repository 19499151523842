@use 'sass:list';
@use '../utils/mixins';
@use '../utils/z-index';

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	@include mixins.size(100%, 30px);

	bottom: 0;
	left: 0;
	margin: auto;
	pointer-events: none;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	z-index: list.index(z-index.$elements, spinner);

	&:not(.animated-loading)::before {
		@include mixins.size(30px);

		bottom: 0;
		content: '';
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;
	}

	&:not(:required)::before {
		animation: spinner 500ms linear infinite;
		border: 2px solid rgba(0, 0, 0, 30%);
		border-radius: 50%;
		border-top-color: rgba(255, 255, 255, 50%);
	}

	.loading-color-animation {
		fill: var(--spinner-loading-animation, rgba(var(--base-02), 1));
		stroke: var(--spinner-loading-animation, rgba(var(--base-02), 1));
	}
}

.spinner-mini {
	@include mixins.size(100%, 30px);

	left: 0;
	margin: auto;
	pointer-events: none;
	position: absolute;
	right: 0;
	text-align: center;
	z-index: list.index(z-index.$elements, spinnerzref);

	&::before {
		@include mixins.size(30px);

		bottom: 0;
		content: '';
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;
	}

	&:not(:required)::before {
		animation: spinner 500ms linear infinite;
		border: 2px solid rgba(0, 0, 0, 30%);
		border-radius: 50%;
		border-top-color: rgba(255, 255, 255, 50%);
	}
}

.dim {
	// Background layer for spinner dimming the viewport
	&::before {
		@include mixins.size(100%);

		content: '';
		left: 0;
		position: absolute;
		top: 0;
		transition: 250ms 500ms ease-in;
		z-index: index(z-index.$elements, modal-view);
	}
}
