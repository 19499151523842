@use 'sass:list';
@use '../utils/mixins';
@use '../utils/z-index';

.modal-view {
	@include mixins.size(100%);

	left: 0;
	opacity: 1;
	position: absolute;
	right: 0;
	top: 0;
	visibility: visible;
	z-index: list.index(z-index.$elements, modal-view);

	&.modal-view--fixed {
		position: fixed;
	}

	&.modal-view--sticky {
		position: sticky;
	}

	&.reality-check-modal {
		z-index: list.index(z-index.$elements, blocking-modal-view);
	}
}

.reality-check-confirm {
	padding-top: var(--regulationHeight);

	.confirm-btn button.btn {
		background-color: inherit;
	}
}
