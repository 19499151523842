@use '../utils/mixins';
@use '../utils/z-index';

.menu {
	@include mixins.size(100%);

	left: 0;
	position: absolute;
	top: 0;
	transition: transform 300ms ease-in;
	z-index: index(z-index.$elements, modal-view);

	&.account-menu,
	&.limits-menu,
	&.verify-phone,
	&.verify-email-menu,
	&.verify-email-result-menu,
	&.upload-menu,
	&.time-limit {
		transform: translate3d(100%, 0, 0);
	}

	&.global-menu,
	&.terms-menu,
	&.privacy-menu,
	&.responsible-menu,
	&.about-menu {
		transform: translate3d(-100%, 0, 0);
	}
}

.global-menu,
.terms-menu,
.account-menu,
.limits-menu,
.privacy-menu,
.responsible-menu,
.about-menu,
.verify-phone,
.verify-email-menu,
.verify-email-result-menu,
.upload-menu,
.time-limit,
.net-deposit-history-info-menu {
	&.menu--is-open {
		-webkit-overflow-scrolling: touch;
		transform: translate3d(0, 0, 0);
	}
}
