@use 'sass:list';
@use '../utils/mixins';
@use '../utils/z-index';

.dots-container {
	padding: 4px 12px;

	svg {
		@include mixins.size(16px);

		fill: var(--btn-dots, rgba(var(--base-11), 1));
	}

	&.dots-container--90deg {
		transform: rotate(90deg) !important;
	}
}

.page-head {
	.dots-container {
		margin-right: -12px;

		.icon--sprite {
			@include mixins.size(24px);
		}
	}
}

.wespin-sidebar .page-head {
	.dots-container {
		margin-right: 0;
	}
}

.support-nav {
	@include mixins.size(100%);

	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: 250ms 500ms ease-in;
	transition: opacity 250ms;
	visibility: hidden;
	z-index: index(z-index.$elements, modal-view);
	z-index: list.index(z-index.$elements, support-nav);

	&.support-nav--is-open {
		background: rgba(var(--base-11), 0.8);
		opacity: 1;
		position: fixed;
		visibility: visible;

		button {
			border-radius: 0;
			font: var(--h4-bold);
			margin-top: 1px;
		}

		button:first-of-type {
			border-radius: 3px 3px 0 0;

			&:nth-last-child(2) {
				border-radius: 3px;
			}
		}

		button:nth-last-child(2) {
			border-radius: 0 0 3px 3px;
		}

		button:last-of-type {
			border-radius: 3px;
			margin-top: 7px;
		}
	}
}

.support-nav__head {
	margin-bottom: 1px;

	.support-nav__main-title {
		font: var(--h4-bold);
		margin-top: 3px;
	}

	.support-nav__sub-title {
		font: var(--caption);
		margin: 3px 0 2px;
	}
}
