@use '../utils/mixins';

.header-wrapper {
	@include mixins.size(100%, 33vh);

	max-height: 212px;
	position: relative;

	.header__title {
		opacity: 1;
		text-align: center;
		transition: 500ms;
		width: 100%;
		z-index: 1;

		&.header__title--hide {
			opacity: 0;
			transform: translateY(-20px);
			transition: 500ms;
		}
	}
}
