@use '../base/shared-variables';
@use 'mixins';
@use 'z-index';

/*
These utility styles should be considered immutable. Immutability makes things predictable,
they aid in scalability, maintenance, and readability by clearing stating what they do.
For example, .u-db should always set display: block; and nothing else. This is similar
to the concept of doing one thing and doing it well. If a particular utility class does
not suite the needs of the mark-up element it’s applied to, remove/replace it from HTML.
*/

// Margin

.u-m0 {
	margin: 0 !important;
}

.u-mt0 {
	margin-top: 0 !important;
}

.u-mr0 {
	margin-right: 0 !important;
}

.u-mb0 {
	margin-bottom: 0 !important;
}

.u-ml0 {
	margin-left: 0 !important;
}

.u-m1 {
	margin: 12px;
}

.u-mt1 {
	margin-top: 12px;
}

.u-mr1 {
	margin-right: 12px;
}

.u-mb1 {
	margin-bottom: 12px;
}

.u-ml1 {
	margin-left: 12px;
}

.u-m2 {
	margin: 20px;
}

.u-mt2 {
	margin-top: 20px;
}

.u-mr2 {
	margin-right: 20px;
}

.u-mb2 {
	margin-bottom: 20px;
}

.u-ml2 {
	margin-left: 20px;
}

.u-mla {
	margin: 0 0 0 auto;
}

.u-mra {
	margin: 0 auto 0 0;
}

.u-mta {
	margin: auto 0 0;
}

.u-mba {
	margin: 0 0 auto;
}

.u-ma {
	margin: auto;
}

.u-divider-1 {
	margin-bottom: 1px;
}

.u-divider-5 {
	margin-bottom: 5px;
}

// Padding

.u-standard-padding {
	padding: shared-variables.$viewPad;
}

.u-p0 {
	padding: 0 !important;
}

.u-pt0 {
	padding-top: 0 !important;
}

.u-pr0 {
	padding-right: 0 !important;
}

.u-pb0 {
	padding-bottom: 0 !important;
}

.u-pl0 {
	padding-left: 0 !important;
}

.u-pt1-2 {
	padding-top: 6px;
}

/* adding these two specific 14px pt and pb to be able
to vertical center text in our modal input.
*/
.u-pt-14 {
	padding-top: 14px !important;
}

.u-pb-14 {
	padding-bottom: 14px !important;
}

@include mixins.breakpoint(sm) {
	.u-p1 {
		padding: 8px !important;
	}

	.u-pt1 {
		padding-top: 8px !important;
	}

	.u-pr1 {
		padding-right: 8px !important;
	}

	.u-pb1 {
		padding-bottom: 8px !important;
	}

	.u-pl1 {
		padding-left: 8px !important;
	}
}

@include mixins.breakpoint(md) {
	.u-p1 {
		padding: 12px !important;
	}

	.u-pt1 {
		padding-top: 12px !important;
	}

	.u-pr1 {
		padding-right: 12px !important;
	}

	.u-pb1 {
		padding-bottom: 12px !important;
	}

	.u-pl1 {
		padding-left: 12px !important;
	}
}

.u-p2 {
	padding: 24px;
}

.u-pt2 {
	padding-top: 24px;
}

.u-pr2 {
	padding-right: 24px !important;
}

.u-pb2 {
	padding-bottom: 24px !important;
}

.u-pl2 {
	padding-left: 24px !important;
}

.u-pr3 {
	padding-right: 32px !important;
}

.u-pb3 {
	padding-bottom: 32px !important;
}

//iOS browser bar hiding content fix

.u-pb-1-btn {
	padding-bottom: 76px !important;
}

.u-pb-2-btn {
	padding-bottom: 140px !important;
}

.u-pb-md {
	padding-bottom: 68px !important;
}

.u-pb-xl {
	padding-bottom: 112px !important;
}

.u-pb-xl2 {
	padding-bottom: 208px !important;
}

// Display

.u-db {
	display: block;
}

.u-show {
	display: block !important;
}

.u-show-dib {
	display: inline-block !important;
}

.u-dn {
	display: none;
}

.u-hide {
	display: none !important;
}

.u-hide-off-canvas {
	bottom: 0;
	position: absolute;
	transform: translate3d(0, 100%, 0);
}

.u-dib {
	display: inline-block;
}

.u-opacity-0 {
	opacity: 0;
}

.u-opacity-0-5 {
	opacity: 0.5;
}

// Flexbox

.u-flex {
	display: flex;
}

.u-flex-row {
	flex-direction: row;
}

.u-flex-1 {
	flex: 1;
}

.u-flex-grow-1 {
	flex-grow: 1;
}

.u-flex-none,
div.u-flex-none {
	flex: none;
}

.u-center-vertically {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.u-top-vertically {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.u-align-vert {
	align-items: center;
}

.u-fxb-center-v-h {
	align-items: center;
	display: flex;
	justify-content: center;
}

.u-fxb-c {
	justify-content: center;
}

.u-space-between {
	justify-content: space-between;
}

.u-fxb-s-b-c {
	align-items: center;
	justify-content: space-between;
}

.u-align-start {
	align-items: flex-start;
}

.u-flex-b50 {
	flex-basis: 50%;
}

.u-flex-available-height {
	display: flex;
	flex: 1 0 auto;
}

.u-flex-available-height-column {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
}

.u-fs0 {
	flex-shrink: 0;
}

.u-fs1 {
	flex-shrink: 1;
}

.u-fs2 {
	flex-shrink: 2;
}

// Floats

.u-fl {
	float: left;
}

.u-fr {
	float: right;
}

// Height

.u-full-height {
	min-height: 100vh;
}

.u-height-100 {
	height: 100%;
}

.u-height-0 {
	height: 0;
}

.u-height-100vw {
	height: 100vw;
}

.u-height-auto {
	height: auto !important;
}

.u-height-100-important {
	height: 100% !important;
}

// Width

.u-1-2-width {
	width: 50% !important;
}

.u-3-4-width {
	width: 74.4%;
}

.u-full-width-break-container {
	@include mixins.breakpoint(sm) {
		margin-left: calc(-1 * 8px);
		width: calc(100% + (2 * 8px));
	}
	@include mixins.breakpoint(md) {
		margin-left: calc(-1 * 12px);
		width: calc(100% + (2 * 12px));
	}
}

.u-full-bleed {
	margin-left: 50%;
	transform: translateX(-50%);
	width: 100vw;
}

.u-width-100 {
	width: 100%;
}

.u-width-95 {
	width: 95%;
}

.u-width-90 {
	width: 90%;
}

.u-width-100-force {
	width: 100% !important;
}

.u-grid-item-100-width-mobile {
	grid-column: span 4;
	grid-row: span 6;
}

.u-m-width-300 {
	max-width: 300px;
}

.u-m-height-200 {
	max-height: 200px;
}

// Position

.u-relative {
	position: relative;
}

.u-absolute {
	position: absolute !important;
}

.u-fixed {
	backface-visibility: hidden;
	position: fixed;
}

.u-static {
	position: static;
}

.u-sticky {
	position: sticky;
}

.u-pos-t0 {
	top: 0;
}

.u-pos-t1 {
	top: 12px;
}

.u-pos-r0 {
	right: 0;
}

.u-pos-r1 {
	right: 12px;
}

.u-pos-b0 {
	bottom: 0;
}

.u-pos-b1 {
	bottom: 12px;
}

.u-pos-b2 {
	bottom: 24px;
}

.u-pos-b3 {
	bottom: 30px;
}

.u-pos-l0 {
	left: 0;
}

.u-pos-l1 {
	left: 12px;
}

.u-pos-tr0 {
	bottom: auto;
	left: auto;
	right: 0;
	top: 0;
}

.u-pos-tl0 {
	bottom: auto;
	left: 0;
	right: auto;
	top: 0;
}

.u-pos-br0 {
	bottom: 0;
	left: auto;
	right: 0;
	top: auto;
}

.u-pos-bl0 {
	bottom: 0;
	left: 0;
	right: auto;
	top: auto;
}

.u-pos-tr1 {
	right: 12px;
	top: 12px;
}

.u-pos-tl10 {
	left: 12px;
	top: 12px;
}

.u-pos-br10 {
	bottom: 12px;
	right: 12px;
}

.u-pos-br2 {
	bottom: 24px;
	right: 24px;
}

.u-pos-br3 {
	bottom: 55px;
	right: 24px;
}

.u-pos-bl10 {
	bottom: 12px;
	left: 12px;
}

.u-pos-c {
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
}

.u-pos-c-and-v {
	bottom: 0;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
}

.u-pos-fixed-c-and-v {
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

// Border-Radius

.u-b-radius {
	border-radius: 3px !important;
}

.u-b-radius-round {
	border-radius: 50%;
}

.u-b-radius-top {
	border-radius: 3px 3px 0 0;
}

.u-b-radius-bottom {
	border-radius: 0 0 3px 3px;
}

.u-b-radius-right {
	border-radius: 0 3px 3px 0 !important;
}

.u-b-radius-right-bottom {
	border-radius: 0 0 3px;
}

.u-b-radius-left {
	border-radius: 3px 0 0 3px !important;
}

.u-b-radius-left-bottom {
	border-radius: 0 0 0 3px;
}

.u-b-radius-top-left {
	border-top-left-radius: 3px;
}

// Overflow

.u-oa {
	overflow-x: hidden;
	overflow-y: auto;
}

/* Scroll Bar syles for custom scrollbar */
.desktop-scrollbar {
	height: 100vh !important;
	width: 100% !important;

	> div:nth-child(3) {
		z-index: 10;
	}
}

.wrapper-campaign-registration .desktop-scrollbar {
	height: 100% !important;
}

.modal-custom-scroll > div {
	display: flex;
	flex-direction: column;
}

.wrapper-checkbox-dropdown-scroll {
	border-bottom: 1px solid rgba(var(--source-of-wealth-dropdown-border), 1);
	bottom: 0 !important;
	height: 60% !important;
	position: absolute !important;
	top: 44px;
	width: 100% !important;
}

// .modal-custom-scroll {
// 	flex-grow: 1;
// 	position: absolute !important;

// 	// To make the bottom button visible and we dont have x scroll
// 	& > div:nth-child(1) {
// 		margin-bottom: 0px !important;
// 		overflow-x: hidden !important;
// 	}

// 	// To make the scroll bar on top of modals
// 	& > div:nth-child(3) {
// 		z-index: 30;
// 	}
// }

.sidebar-custom-scroll {
	position: absolute !important;

	// To make the bottom button visible and we dont have x scroll
	> div:nth-child(1) {
		margin-bottom: 0 !important;
		overflow-x: hidden !important;
	}

	// To make the scroll bar on top of modals
	> div:nth-child(3) {
		z-index: 30;
	}
}

.u-oh {
	overflow: hidden;

	// To remove scroll bar during modals that dont need scroll
	> .sidebar-custom-scroll > div:first-child {
		margin-right: 0 !important;
		overflow: hidden !important;
	}
}

// Truncate

.u-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.u-truncate--left {
	direction: rtl;
}

.u-break-long-words {
	word-break: break-all;
}

.u-text-indent {
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
}

.u-multi-line-truncate {
	-webkit-box-orient: vertical;
	display: flex;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

// Align

.u-div-c {
	margin-left: auto;
	margin-right: auto;
}

.u-text-l {
	text-align: left;
}

.u-text-c {
	text-align: center;
}

.u-text-r {
	text-align: right;
}

// Capitalize

.u-capitalize {
	text-transform: capitalize;
}

// Uppercase

.u-uppercase {
	text-transform: uppercase;
}

.u-uppercase-not-placeholder {
	text-transform: uppercase;

	&::-webkit-input-placeholder {
		/* WebKit browsers */
		text-transform: none;
	}

	&::-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		text-transform: none;
	}

	&::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		text-transform: none;
	}

	&::-ms-input-placeholder {
		/* Internet Explorer 10+ */
		text-transform: none;
	}
}

// Cursor

.u-cursor-hand {
	cursor: pointer;
}

// Pointer Events

.u-pointer-events-none {
	pointer-events: none;
}

// Wrappers

.u-scroll-content-wrapper {
	height: 100%;
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
}

// Control Parent

.u-transform-none {
	transform: none !important;
}

// Background

body {
	.u-bg-trans {
		background-color: transparent;
		background-image: none;
	}
}

.u-bg-info {
	background-color: rgba(var(--info), 1) !important;
}

.u-bg-inherit {
	background-color: inherit;
}

.u-bg-overlay {
	background-color: rgba(var(--base-11), 0.95) !important;
}

.u-bg-overlay-70 {
	background-color: rgba(var(--base-11), 0.7) !important;
}

// Table

.u-no-break {
	white-space: nowrap;
}

.u-break-word {
	word-break: break-word;
}

// Invisible

.u-text-hide {
	color: transparent !important;
}

.u-hide-visiblity {
	visibility: hidden;
}

// Center classes (absolute elements)

.u-absolute--center-vertically {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.u-absolute--center-horizontally {
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
}

//	For deposit limits support menu needs

.u-z-index-11 {
	z-index: 11;
}

.u-z-index-spinner {
	z-index: index(z-index.$elements, notification) + 5 !important;
}

// z-index for iframe
.u-z-index-iframe {
	z-index: index(z-index.$elements, ingame);
}

// iframe

.u-iframe-full-screen {
	height: 100%;
	padding-bottom: 44px;
	position: absolute;
	width: 100%;
}

// Blur

.u-blur-3 {
	filter: blur(3px);
}

// Background color

.u-success-bg {
	background-color: rgba(var(--success), 1) !important;
}

.u-opac-25 {
	opacity: 0.25;
}

// Divider / Border bottom
.u-divider {
	border-bottom: 1px solid var(-list-border-bottom);
}

.u-object-fit-cover {
	object-fit: cover;
}

// Transitions
.u-transition-ease-in {
	transition: all 0.2s ease-in;
}

.u-transition-ease-in-out {
	transition: all 0.2s ease-in-out;
}
