@use 'sass:list';
@use 'sass:meta';

/* base/selectors */
@use 'utils/mixins';
@use 'utils/z-index';

html {
	box-sizing: border-box;
	font-size: 62.5%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

button {
	appearance: none;
	background: transparent;
	border: 0;
	border-radius: 0;
	cursor: pointer;
	font-size: inherit;
	font-style: inherit;
	padding: 0;
}

svg {
	display: block;
	fill: currentcolor;
	pointer-events: none;
	vertical-align: -0.15em;
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

/* base/type */

body,
h1,
div,
a,
span,
p,
footer {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizespeed;
}

h1,
p {
	margin: 0;
	text-rendering: optimizelegibility;
}

p {
	font: var(--label-regular);
	margin: 0;
}

p + p {
	margin-top: 8px;
}

a {
	color: inherit;
	display: block;
	font-size: inherit;
	text-decoration: none;
}

/* components/buttons */

.btn {
	display: inline-block;
	font: var(--h3);
	padding: 14px;
	text-align: center;
	transition: transform 250ms ease-out, color 250ms ease-out;
	width: 100%;
}

.btn.btn--plate {
	border: 0;
	font: var(--label-regular);
	padding: 8px 12px;
	right: 12px;
	top: 12px;
	width: auto;
	z-index: 4;
}

/* components/flags */

.flag {
	background-repeat: no-repeat;
	height: 48px;
	width: 48px;
}

.flag.flag--cl {
	background-image: url('../../img/svg/flags/flag__chile.svg');
}

.flag.flag--de {
	background-image: url('../../img/svg/flags/flag__germany.svg');
}

.flag.flag--dk {
	background-image: url('../../img/svg/flags/flag__denmark.svg');
}

.flag.flag--en {
	background-image: url('../../img/svg/flags/flag__global.svg');
}

.flag.flag--eu {
	background-image: url('../../img/svg/flags/flag__eu.svg');
}

.flag.flag--fi {
	background-image: url('../../img/svg/flags/flag__finland.svg');
}

.flag.flag--gb {
	background-image: url('../../img/svg/flags/flag__united_kingdom.svg');
}

.flag.flag--no {
	background-image: url('../../img/svg/flags/flag__norway.svg');
}

.flag.flag--pl,
.flag.flag--po {
	background-image: url('../../img/svg/flags/flag__poland.svg');
}

.flag.flag--se,
.flag.flag--sv {
	background-image: url('../../img/svg/flags/flag__sweden.svg');
}

/* components/header */

div.landing-page-wrapper .page-head {
	background: none;
	display: flex;
	height: 52px;
	overflow-x: hidden;
	transition: transform 250ms ease-in, background-color 250ms ease-in, color 250ms ease-in;
	white-space: nowrap;
	width: 100%;
	z-index: 3;
}

div.landing-page-wrapper .header-logo-wrapper {
	width: 100%;
}

div.landing-page-wrapper .header-logo-wrapper .svg-logo {
	display: block;
	height: 44px;
	width: 100%;
}

/* components/link-list */

.link-list {
	margin: 8px 0;
}

.link-list__item {
	cursor: pointer;
	font: var(--label-regular);
	margin-bottom: 1px;
}

.link-list .link-list__item-link {
	align-items: center;
	display: flex;
	min-height: 52px;
	padding: 14px 12px;
	width: 100%;
	word-break: break-word;
}

.link-list__continue-icon {
	margin: 0 0 0 auto;
	position: relative;
}

.link-list__item-link:last-child {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.link-list__item-link:first-child {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

/* layout/footer */

.footer {
	padding: 24px 12px 268px;
}

.licenses-logos {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.licenses-logos__item {
	padding: 0 4px;
}

.licenses-logos__mga {
	background: url('../../img/svg/mga-licence.svg') no-repeat;
	height: 41px;
	width: 148px;
}

.licenses-logos_app {
	margin: 1.6rem auto;
	width: fit-content;
}

.licenses-logos__curacao {
	filter: grayscale(1);
	margin-left: -6px;
	width: 56px;
}

/* layout/lading-page */

div.landing-page-wrapper .landing-page__fold {
	height: 100vh;
}

div.fixed-background {
	background-position: left;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	position: fixed;
	width: 100%;

	.fixed-background-layer {
		background-color: #202020;
		height: 100vh;
		opacity: 0.7;
	}
}

div.landing-page-wrapper {
	height: 100%;
	min-height: 100vh;
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
	padding-top: 64px;
	position: absolute;
	top: 0;
	width: 100%;
}

div.landing-page-wrapper .btn--plate {
	top: 8px;
}

div.landing-page-wrapper .landing-page-wrapper__logo-wrapper.is-collapsed {
	visibility: hidden;
}

div.landing-page-wrapper .landing-page-wrapper__logo-wrapper > .svg-logo {
	margin: 36px auto 0;
	max-width: 300px;
	transition: 250ms;
}

div.landing-page-wrapper .header-logo-wrapper {
	background-color: rgba(22, 22, 29, 85%);
	transition: background-color 250ms ease;
}

div.landing-page-wrapper .header-logo-wrapper {
	@media (min-width: 48em) {
		background-color: transparent;
	}
}

div.landing-page-wrapper .header-logo-wrapper.is-collapsed {
	background-color: rgba(22, 22, 29, 0%);
}

div.landing-page-wrapper .header-logo-wrapper > .svg-logo {
	transform: translate(0, 0) scale3d(0.5, 0.5, 1);
}

div.landing-page-wrapper .header-logo-wrapper.is-collapsed > .svg-logo {
	opacity: 0;
}

div.landing-page-wrapper .landing-page-wrapper__message-title {
	color: rgba(var(--base-02), 1);
	font: var(--h1);
	margin: 24px 24px 48px;
}

div.landing-page-wrapper .products {
	justify-content: center;
	margin: 0 auto;
	max-width: 320px;
}

div.landing-page-wrapper .product-button a {
	align-items: center;
	display: flex;
	flex-direction: column;
}

div.landing-page-wrapper .products__link {
	height: 56px;
	width: 56px;
}

div.landing-page-wrapper .landing-page-wrapper__icon {
	height: 32px;
	width: 32px;
}

div.landing-page-wrapper .products__text {
	color: rgba(var(--base-02), 1);
	font: var(--label-regular);
	margin-top: 8px;
}

div.landing-page-wrapper .plus-18 {
	font: var(--h4-bold);
	height: 48px;
	width: 48px;
}

/* layouts/log-in */

.bottom-wrapper {
	bottom: 0;
	overflow: hidden;
	position: absolute;
	transition: transform 250ms ease-in;
	width: 100%;
	z-index: 5;
}

.bottom-wrapper.enable-transition {
	transition: 250ms 250ms;
}

.bottom-wrapper--is-hidden {
	transform: translateY(110%);
}

/* utils/utility-classes */

.u-ml1 {
	margin-left: 12px;
}

.u-mt1 {
	margin-top: 12px;
}

.u-mt2 {
	margin-top: 20px;
}

.u-mr2 {
	margin-right: 20px;
}

.u-ml2 {
	margin-left: 20px;
}

.u-p1 {
	padding: 12px !important;
}

.u-pt1 {
	padding-top: 12px !important;
}

.u-pb1 {
	padding-bottom: 12px !important;
}

.u-pr1 {
	padding-right: 12px !important;
}

.u-pl1 {
	padding-left: 12px !important;
}

.u-pt2 {
	padding-top: 24px !important;
}

.u-pb1 {
	padding-bottom: 12px !important;
}

.u-pr2 {
	padding-right: 24px !important;
}

.u-pl2 {
	padding-left: 24px !important;
}

.u-b-radius {
	border-radius: 3px !important;
}

.u-b-radius-round {
	border-radius: 50%;
}

.u-oh {
	overflow: hidden;
}

.u-relative {
	position: relative;
}

.u-absolute {
	position: absolute;
}

.u-fixed {
	backface-visibility: hidden;
	position: fixed;
}

.u-fixed-fallback {
	position: fixed;
	transform: translateZ(0);
}

.u-pos-tl0 {
	bottom: auto;
	left: 0;
	right: auto;
	top: 0;
}

.u-flex {
	display: flex;
}

.u-flex-grow-1 {
	flex-grow: 1;
}

.u-fxb-center-v-h {
	align-items: center;
	display: flex;
	justify-content: center;
}

.u-fl {
	float: left;
}

.u-fr {
	float: right;
}

.u-text-c {
	text-align: center;
}

.u-hide {
	display: none;
}

.global-notification,
.deposit,
.withdrawal,
.verify-email-menu,
.verify-email-result-menu,
.verify-phone {
	opacity: 0;
	visibility: hidden;
}

.verify-email-result-menu .page-head .page-head__back svg {
	fill: rgba(var(--base-02), 1);
}

/* PERSES */

@include meta.load-css('utils/mixins');
@include meta.load-css('utils/z-index');
@include meta.load-css('components/header');

%overlay {
	@include mixins.size(100%);

	left: 0;
	position: absolute;
	top: 0;
	transition: 250ms 500ms ease-in;
	z-index: list.index(z-index.$elements, modal-view);
}

.link-list__item {
	.link-list__item-link {
		svg,
		.link-list__continue-icon {
			margin: 0 10px 0 0;
		}

		.link-list__continue-icon {
			margin: 0 0 0 auto;
			position: relative;
		}
	}

	.link-list__value {
		margin: 0 0 0 auto;
		white-space: nowrap;
	}

	.link-list__value + .link-list__continue-icon {
		margin: 0;
	}

	span {
		font-family: inherit;
	}
}

/* Styles for Iframe overlay loader */

div.iframe-overlay.spinner {
	background: var(--sidebar-bg, rgba(var(--base-11), 1));
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100;
}

div.spinner-overlay {
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
}

.casino-bg {
	background: transparent;
	background-attachment: fixed;
	background-size: cover;
}

.licenses-logos__igo {
	@include mixins.size(170px, 68px);

	background: url('../../img/svg/igaming-ontario-license.svg') no-repeat;
}
