@use 'sass:list';
@use '../utils/mixins';
@use '../utils/z-index';

.my-account-notifications,
.global-notification {
	opacity: 0;
	visibility: hidden;

	&.my-account-notifications--is-open,
	&.global-notification--is-open {
		@include mixins.size(100%);

		left: 0;
		opacity: 1;
		position: fixed;
		top: 0;
		visibility: visible;
		z-index: list.index(z-index.$elements, notification);
	}
}

.notification-wrap {
	min-height: 44px;
	transform: translate3d(0, -100%, 0);
	transition: transform 250ms;
	z-index: list.index(z-index.$elements, notification-wrap);
	@include mixins.breakpoint(lg) {
		display: flex;
	}

	&.notification-wrap--sga {
		top: calc(-100% - 44px);
	}

	&.notification-wrap--open--sga {
		top: 44px;
	}

	&.no-transition {
		transition: none;
	}

	&.button-bottom {
		flex-direction: column;

		.notification-button {
			margin: 0;
			max-width: none;
		}
	}

	&--open {
		transform: translate3d(0, 0, 0);
	}

	&--close {
		transform: translate3d(0, -100%, 0);
	}

	.page-head__close {
		&.icon-alignment-fix {
			@include mixins.breakpoint(lg) {
				top: 17px;
			}
		}
	}

	.notification-wrap__error {
		padding: 13px 12px 11px 47px;

		.btn.btn--auto {
			padding: 8px 16px;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		font: var(--label-regular);
	}

	.notification-wrap__error-text-header {
		font: var(--h4-bold);
	}

	.notification-button {
		@include mixins.breakpoint(lg) {
			margin: auto 0 auto auto;
			max-width: 33%;
		}

		.btn--ghost {
			@include mixins.breakpoint(lg) {
				white-space: normal;
			}
		}
	}
}

.notifications-wrapper {
	position: fixed;
	width: 100%;
	z-index: list.index(z-index.$elements, notification-wrap);
}

//notification - inline

// BINARY QUESTION MODAL

.binary-question {
	.binary-question__inner {
		margin-top: 120px;
		max-width: 491px;
		width: 74.4%;
	}

	.binary-question__main-title {
		font: var(--h3);
		//margin-bottom: 2px;
	}

	.input-wrap__input,
	.input-wrap .input-wrap__input {
		margin-top: 16px;
		padding: 10px 0;
		text-align: center;
	}

	.binary-question__button-container {
		display: flex;
		flex-wrap: wrap;

		.binary-question__yes,
		.binary-question__no,
		.binary-question__third {
			background: none;
			border-radius: 0 0 0 3px;
			flex-grow: 1;
			font: var(--h4-bold);
			min-width: 50%;
			width: auto;
		}

		.binary-question__yes {
			border-radius: 0 0 3px;
		}
	}
}

.binary-question {
	&.binary-question--fixed {
		position: fixed;
	}

	&.binary-question--sticky {
		position: sticky !important;
	}
}

// NOTIFY

.notify {
	@include mixins.size(100%);

	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: 250ms 500ms ease-in;
	transition: opacity 300ms ease-in;
	visibility: hidden;
	z-index: index(z-index.$elements, modal-view);

	&.notify--is-open {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		visibility: visible;
		z-index: index(z-index.$elements, notification);

		&[data-error-code='SGA_MANDATORY_LIMIT_ERROR_NOT_ALL_TIME_LIMITS_SET'],
		&[data-error-code='SGA_MANDATORY_LIMIT_ERROR_PRODUCT_NOT_SELECTED'] {
			z-index: 15;
		}
	}
}

@supports (-webkit-touch-callout: none) {
	.js-notify__button-wrapper.js-notify__button-wrapper--failed {
		position: sticky;
	}
}

.notify__icon-wrapper {
	@include mixins.breakpoint(xl) {
		margin: 64px auto 5px;
	}

	svg {
		@include mixins.size(56px);

		margin: 0 auto;
	}
}

.notify__head {
	padding: 60px 12px 84px;

	svg {
		@include mixins.size(56px);

		margin: 0 auto;
	}
}

.notify__main-title {
	font: var(--h2);
	margin: 8px 0 2px;
	padding: 0 12px;
}

.notify__p {
	font: var(--label-regular);
	padding: 0 32px;
}

.notify__link {
	display: inline;
	text-decoration: underline;
}

// PROGRESS BAR

.notify__progress-bar {
	@include mixins.size(100%);

	position: absolute;
	top: 0;
	transform: translate3d(0, 100%, 0);
	transition: transform 500ms ease-in;
	z-index: -1;

	&.notify__progress-bar--fill-success {
		transform: translate3d(0, 0, 0);
	}

	&.notify__progress-bar--fill-fail {
		overflow: hidden;
		transform: translate3d(0, 0, 0);
	}
}

// OPTION BOX

.option-box {
	margin-top: -120px;
	width: 74.4%;

	.user-options {
		border-bottom: 0;

		.filters {
			input {
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter {
					border-bottom: 0;
					padding: 6px 0;
				}
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter:not(:last-child) {
					margin-right: 1px;
				}
			}
		}
	}

	.filters {
		label:first-of-type {
			border-radius: 3px 0 0 3px;
		}

		label:last-of-type {
			border-radius: 0 3px 3px 0;
		}
	}
}

// OVERLAY BEHIND MODAL

// COOKIE CONSENT

.cookieConsent {
	display: flex;
	flex-wrap: wrap;
	position: fixed;
	width: 100%;
	z-index: list.index(z-index.$elements, cookie-consent);

	a {
		text-decoration: underline;
	}
}

// PAYMENT METHODS

.payment-result-wrapper .payment-methods {
	margin-bottom: 90px;
	margin-top: 40px;
	padding: 0;
	text-align: left;

	p:first-child {
		font: var(--label-bold);
		margin-bottom: 12px;
	}

	a:first-child {
		.payment-methods__item {
			border-radius: 3px 3px 0 0;
		}
	}

	a:last-child {
		.payment-methods__item {
			border-radius: 0 0 3px 3px;
		}
	}

	a:only-child {
		.payment-methods__item {
			border-radius: 3px;
		}
	}

	&__item {
		align-items: center;
		display: flex;
		height: 71px;
		margin-top: 1px;
		position: relative;
		width: 100%;

		&__logo {
			svg.logo-payment-method {
				height: auto;
			}
		}

		&__text {
			width: 70%;

			h2,
			p {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		&__chevron {
			height: 29px;
			margin: 0;
			margin-right: 4px;
			position: absolute;
			right: 11px;
			width: 27px;

			.link-list__continue-icon {
				height: 28px;
			}
		}
	}
}

.sb-tutorial-link {
	padding-top: 25px;

	h2 {
		margin-bottom: 3px;
	}

	&__item {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-top: 12px;
		padding-right: 0 !important;

		svg.link-list__continue-icon,
		img.link-list__continue-icon {
			height: 29px;
			margin: 0;
			width: 27px;
		}
	}
}

// Two Factor auth on execute deposit

.two-fact {
	.binary-question__button-container {
		margin-top: 25px;
	}

	&__error-label {
		font: var(--small);
		margin: 14px 20% 0;
	}

	h2.js-title {
		line-height: 24px;
		margin-bottom: 4px;
	}

	p.js-text {
		line-height: 20px;
	}
}
