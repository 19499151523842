@use 'sass:list';
@use '../base/shared-variables';
@use '../utils/mixins';
@use '../utils/z-index';

.btn-container {
	animation: slideIn 250ms forwards;
}

.btn-container--double {
	.btn {
		white-space: nowrap;
	}

	.btn:first-child,
	.btn--secondary:first-child {
		border-radius: var(--button-border-radius, 3px) 0 0 var(--button-border-radius, 3px);
	}

	.btn:last-child,
	.btn--secondary:last-child {
		border-radius: 0 var(--button-border-radius, 3px) var(--button-border-radius, 3px) 0;
		margin-left: 1px;
	}

	.btn:only-child,
	.btn--secondary:only-child {
		border-radius: var(--button-border-radius, 3px);
		margin-left: 0;
	}
}

.btn-container--portrait {
	.btn:not(:first-child),
	.btn--secondary:not(:first-child) {
		margin-top: 8px;
	}
}

.btn-container--double-link {
	.btn--left,
	.btn--right,
	.deposit-btn {
		border-radius: var(--button-border-radius, 3px);
		display: flex;
		flex: 1;
	}

	.btn--left {
		.btn {
			border-radius: var(--button-border-radius, 3px) 0 0 var(--button-border-radius, 3px);
		}
	}

	.btn--right {
		.btn {
			border-radius: 0 var(--button-border-radius, 3px) var(--button-border-radius, 3px) 0;
			margin-left: 1px;
		}
	}
}

.btn-container-wrapper {
	width: 100%;
	@include mixins.breakpoint(xl) {
		width: 704px;
	}
}

.menu {
	.page-head {
		.svg-logo {
			//@include mixins.size(176px, 32px);
			display: flex;
			height: var(
				--page-head-logo-height
			); //if logo under global menu looks off, add this custom-prop. Should be added to all brands.

			margin: 8px auto 0;
			width: 176px;
		}
	}
}

.log-in__form {
	padding: 0 0 12px;
}

.bottom-wrapper {
	bottom: 0;
	overflow: hidden;
	position: absolute;
	transition: transform 250ms ease-in;
	width: 100%;
	z-index: list.index(z-index.$elements, bottom-wrapper);

	&.enable-transition {
		transition: 250ms 250ms;
	}

	&--is-hidden {
		transform: translateY(110%);
	}
}

.log-in--open {
	@include mixins.size(100%, 100%);

	transform: translate3d(0, 0, 0);

	.btn-container {
		animation: bounceOutRight 250ms forwards;
		position: absolute;
	}
}

.user-options {
	&.user-options--login,
	&.user-options--register {
		.filters input + .filters__filter {
			background-color: transparent;
		}
	}
}

.svg-logo-container {
	display: flex;
	justify-content: center;
	padding: 26px 12px 38px;

	.svg-logo {
		@include mixins.size(100px);
	}
}

.grecaptcha-badge {
	display: none;
}
