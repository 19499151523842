@use 'sass:list';
@use '../utils/mixins';
@use '../utils/z-index';

.page-head {
	@include mixins.size(100%, 44px);

	display: flex;
	transition: transform 250ms ease-in, background-color 250ms ease-in, color 250ms ease-in;
	white-space: nowrap;
	z-index: list.index(z-index.$elements, header);

	.page-head__title {
		font: var(--h4-bold);
		justify-content: center;

		.svg-logo {
			@include mixins.size(--register-one-step-header-logo-width, 32px);

			display: block;
			margin: auto 0;
			@include mixins.breakpoint(xl) {
				display: flex;
			}
		}
	}

	.svg-logo {
		@include mixins.size(176px, 32px);

		margin: auto 0;
		@include mixins.breakpoint(xl) {
			display: flex;
		}
	}
}

.page-head__title,
.page-head__left-side,
.page-head__right-side {
	display: flex;
	flex: 1;
	margin: auto 0;
}

.page-head__left-side {
	justify-content: flex-start;
	margin: auto auto auto 12px;
	z-index: 1;

	.page-head__icon {
		padding: 10px 12px 10px 0;

		.game-item__love--loading {
			cursor: default;

			svg.item__love-icon,
			.item__love-counter {
				opacity: 0.3;
			}
		}
	}
}

.page-head__right-side {
	justify-content: flex-end;
	margin: auto 12px auto auto;

	.header-icon--deposit {
		margin-right: 12px;
	}
}

.header-icon--login {
	@include mixins.breakpoint(lg) {
		display: none;
	}
}

.header-unread-message-notifier {
	align-items: center;
	background-color: rgba(var(--unread-message-counter-bg-color), 1);
	border-radius: 8px;
	color: rgba(var(--unread-message-counter-text-color), 1) !important;
	display: flex;
	font: var(--small-bold);
	height: 16px;
	justify-content: center;
	min-width: 16px;
	padding: 2px 4px;
	pointer-events: none;
	position: absolute;

	&.galaxy {
		right: -7px;
		top: -4px;

		&.ingame {
			right: -4px;
			top: -6px;
		}
	}
}
