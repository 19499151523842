@use '../utils/mixins';

.my-limits-menu {
	.link-list {
		margin-bottom: 0;
	}
}

.welcome-menu,
.my-limits-menu {
	.limits-read-more {
		font: var(--label-regular);
		margin-left: 10px;
		text-decoration: underline;
	}

	.limits-notification-wrapper {
		background-color: rgba(var(--base-10), 1);
		padding: 9px 24px 9px 9px;

		* {
			color: rgba(var(--base-02), 1);
		}

		.limits-notification-icon-wrapper {
			margin-right: 12px;
			min-width: 48px;
			width: 48px;
		}

		.limits-notification-icon {
			@include mixins.size(100%);

			fill: rgba(var(--limits-notification-icon), 1);
		}

		.icon-close--small {
			padding: 8px;

			svg {
				@include mixins.size(16px);

				fill: rgba(var(--limits-notification-icon), 1);
			}
		}
	}

	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-10), 1);
			padding: 16px 12px;
			text-align: left;
			width: 100%;

			&::before {
				right: 8px;
				top: 26px;
			}

			&::after {
				right: 15px;
				top: 19px;
			}
		}
	}

	.accordion__content {
		background-color: rgba(var(--base-10), 1);
		margin-top: 1px;

		.accordion__content-text {
			padding: 16px 0;
		}
	}
}

.retrieve-limits-modal {
	.default-limit-btn {
		background-color: transparent;
		border: 1px solid rgba(var(--primary), 1);
		color: rgba(var(--primary), 1);
	}

	.choose-amount {
		text-decoration: underline;
	}
}
