@use '../utils/mixins';

.category-carousel {
	display: flex;
	grid-column: 1/3;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	overflow-y: auto;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
	width: calc(100% + 24px);
	@include mixins.breakpoint('md') {
		grid-column: 1/-1;
	}

	.gallery__cell {
		flex-shrink: 0;
		padding: 12px 16px;
		scroll-snap-align: start;
	}
}

.category-carousel::-webkit-scrollbar {
	display: none;
}
