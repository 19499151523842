@use 'sass:list';
@use '../utils/z-index';

.filters {
	z-index: list.index(z-index.$elements, filters);

	input {
		left: -9999px;
		opacity: 0;
		position: absolute;

		+ .filters__filter {
			flex-basis: 0; /* Makes all filter same width */
			flex-grow: 1;
			font: var(--label-bold);
			padding: 16px 0;
		}

		+ .filters__filter::first-letter {
			text-transform: uppercase;
		}

		&:checked,
		&.active {
			&:nth-of-type(1) ~ .sliding-border {
				transform: translateX(0);
			}

			&:nth-of-type(2) ~ .sliding-border {
				transform: translateX(100%);
			}

			&:nth-of-type(3) ~ .sliding-border {
				transform: translateX(200%);
			}

			&:nth-of-type(4) ~ .sliding-border {
				transform: translateX(300%);
			}
		}
	}

	.sliding-border {
		transition: transform 250ms ease-out;
		width: 100%;
	}

	.filters__inner-wrapper {
		//TODO: fix this, reduce nesting REV-318
		//stylelint-disable-next-line selector-max-compound-selectors
		.filters__filter ~ .filters__filter ~ .sliding-border {
			width: 50%;
		}
		//TODO: fix this, reduce nesting REV-318
		//stylelint-disable-next-line selector-max-compound-selectors
		.filters__filter ~ .filters__filter ~ .filters__filter ~ .sliding-border {
			width: 33%;
		}
		//TODO: fix this, reduce nesting REV-318
		//stylelint-disable-next-line selector-max-compound-selectors
		.filters__filter ~ .filters__filter ~ .filters__filter ~ .filters__filter ~ .sliding-border {
			width: 25%;
		}
	}
}

.filters--login {
	input {
		+ .filters__filter::first-letter {
			text-transform: none;
		}
	}
}
