@use '../utils/mixins';

.template-document-wrapper {
	overflow-x: hidden;
	padding: 8px 0 12px;
}

.template-document-wrapper::after {
	@include mixins.size(100%, 2px);

	bottom: -12px;
	content: '';
	display: block;
	left: -1px;
	position: relative;
	transform: translate3d(-100%, 0, 0);
}

.remove-button {
	svg {
		bottom: -3px;
		position: relative;
		width: 24px;
	}

	img {
		bottom: -3px;
		position: relative;
		width: 24px;
	}
}

.template-document-date,
.template-document-info {
	display: flex;

	div {
		flex: 1 1 50%;
	}
}

.template-document-date {
	div:first-child {
		line-height: 26px;
	}
}

.template-document-date {
	div {
		border: 0;
		font: var(--caption);
	}
}

.template-document-info {
	div {
		font: var(--h4-regular);
	}
}

.upload-menu > .upload-file--button {
	bottom: 0;
	position: absolute;
	width: 100%;
}

.upload-file {
	padding: 0 0 6px;

	input[type='file'] {
		display: none;
	}

	input[type='file'] + label {
		align-items: center;
		border-radius: 3px;
		display: flex;
		font: var(--h4-regular);
		left: 0;
		padding: 16px 12px 15px;
		transform: none;
		width: 100%;
		word-break: break-word;
	}
}

/* ANIMATION */

.template-document-wrapper.new-document {
	animation: pop-up 1000ms;

	.template-document-info-status {
		animation: show-status 500ms 4700ms ease-in;
		animation-fill-mode: backwards;
	}

	&::after {
		animation: slide-in 3500ms 1200ms ease-out;
	}
}

@keyframes pop-up {
	0% {
		opacity: 0;
		transform: translateY(73px);
	}

	60% {
		opacity: 1;
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes slide-in {
	0% {
		transform: translate3d(-100%, 0, 0);
	}

	65% {
		transform: translate3d(0, 0, 0);
	}

	95% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		transform: translate3d(0, 2px, 0);
	}
}

@keyframes show-status {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
