@use '../utils/mixins';

.ingame {
	.licenses-logos__spelinspektionen {
		@include mixins.size(40px, 30px);

		background: url('../../img/svg/spelinspektionen-licence.svg') no-repeat;
	}
}

.responsive-footer-logo {
	.licenses-logos__spelinspektionen {
		@include mixins.size(48px, 36px);

		background: url('../../img/svg/spelinspektionen-licence.svg') no-repeat;
	}

	.licenses-logos__spillemyndigheden {
		@include mixins.size(120px, 120px);

		background: url('../../img/svg/spillemyndigheden-license.svg') no-repeat;
	}

	.licenses-logos__igo {
		@include mixins.size(170px, 68px);

		background: url('../../img/svg/igaming-ontario-license.svg') no-repeat;
	}

	.licenses-logos__iso-icon {
		@include mixins.size(80px, 90px);

		background: url('../../img/png/certification-logos/ISOIEC27001_White.png') no-repeat;
		background-size: 80px 90px;
	}
}

.responsive-footer-egr-logo {
	.licenses-logos__egr-icon-1 {
		@include mixins.size(65px, 90px);

		background: url('../../img/png/egr-logos/Awards-logo-01.png') no-repeat;
		background-size: 65px 90px;
	}

	.licenses-logos__egr-icon-2 {
		@include mixins.size(65px, 90px);

		background: url('../../img/png/egr-logos/Awards-logo-02.png') no-repeat;
		background-size: 65px 90px;
	}

	.licenses-logos__egr-icon-3 {
		@include mixins.size(65px, 90px);

		background: url('../../img/png/egr-logos/Awards-logo-03.png') no-repeat;
		background-size: 65px 90px;
	}

	.licenses-logos__egr-icon-4 {
		@include mixins.size(65px, 90px);

		background: url('../../img/png/egr-logos/Awards-logo-04.png') no-repeat;
		background-size: 65px 90px;
	}

	.licenses-logos__egr-icon-5 {
		@include mixins.size(65px, 90px);

		background: url('../../img/png/egr-logos/Awards-logo-05.png') no-repeat;
		background-size: 65px 90px;
	}

	.licenses-logos__egr-icon-6 {
		@include mixins.size(65px, 90px);

		background: url('../../img/png/egr-logos/Awards-logo-06.png') no-repeat;
		background-size: 65px 90px;
	}

	.licenses-logos__egr-icon-7 {
		@include mixins.size(65px, 90px);

		background: url('../../img/png/egr-logos/Awards-logo-07.png') no-repeat;
		background-size: 65px 90px;
	}
}

.flex-list-footer {
	.licenses-logos__item {
		padding: 0 4px;
	}

	.licenses-logos__ibas {
		@include mixins.size(60px, 40px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: 0 -40px;
	}

	.licenses-logos__gamcare {
		@include mixins.size(61px, 33px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: 0 -125px;
	}

	.licenses-logos__gamstop {
		@include mixins.size(59px, 11px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: -58px -96px;
	}

	.licenses-logos__five-stars {
		@include mixins.size(47px, 35px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: 0 -90px;
	}

	.licenses-logos__spillemyndigheden {
		@include mixins.size(120px, 120px);

		background: url('../../img/svg/spillemyndigheden-license.svg') no-repeat;
	}

	.licenses-logos__gambling-commission {
		@include mixins.size(96px, 33px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: 0 -200px;
	}

	.licenses-logos__spelinspektionen {
		@include mixins.size(48px, 36px);

		background: url('../../img/svg/spelinspektionen-licence.svg') no-repeat;
	}
}

.footer {
	@include mixins.breakpoint(sm) {
		padding: 0 8px 268px;
	}
	@include mixins.breakpoint(md) {
		padding: 0 12px 268px;
	}

	.licenses-logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.licenses-logos__item {
			padding: 0 4px;
		}
	}

	.licenses-logos__mga {
		@include mixins.size(148px, 41px);

		background: url('../../img/svg/mga-licence.svg') no-repeat;
	}

	.licenses-logos_app {
		margin: 1.6rem auto;
		width: fit-content;
	}

	.licenses-logos__curacao {
		filter: grayscale(1);
		margin-left: -6px;
		width: 56px;
	}

	.licenses-logos__ibas {
		@include mixins.size(60px, 40px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: 0 -40px;
	}

	.licenses-logos__gamcare {
		@include mixins.size(61px, 33px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: 0 -125px;
	}

	.licenses-logos__gamstop {
		@include mixins.size(59px, 11px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: -58px -96px;
	}

	.licenses-logos__five-stars {
		@include mixins.size(47px, 35px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: 0 -90px;
	}

	.licenses-logos__spillemyndigheden {
		@include mixins.size(120px, 120px);

		background: url('../../img/svg/spillemyndigheden-license.svg') no-repeat;
	}

	.licenses-logos__gambling-commission {
		@include mixins.size(96px, 33px);

		background: url('../../img/png/sprite-footer.png') no-repeat;
		background-position: 0 -200px;
	}

	.licenses-logos__spelinspektionen {
		@include mixins.size(48px, 36px);

		background: url('../../img/svg/spelinspektionen-licence.svg') no-repeat;
	}

	.licenses-logos__igo {
		@include mixins.size(120px, 120px);

		background: url('../../img/svg/igaming-ontario-license.svg') no-repeat;
	}

	.plus-18 {
		@include mixins.size(48px);

		font: var(--h4-bold);
	}
}

.logo-wrapper {
	ul {
		display: flex;
		justify-content: center;
	}

	li {
		flex: 0 1 calc(20% - 12px);
		margin: 24px 0;

		a {
			display: flex;
			justify-content: center;
		}
	}

	img {
		width: 48px;
	}
}

.footer-trust-logos,
.logo-wrapper {
	@include mixins.breakpoint(lg) {
		margin: 0 auto;
		max-width: 628px;

		img {
			width: 96px;
		}
	}
}

.footer-logos {
	.footer-logos__container {
		align-items: center;
		display: flex;
		gap: 25.5px;
		justify-content: center;
		margin: 24px 0;
		width: 100%;
	}

	.footer-logos__logo {
		@include mixins.size(96px, 96px);

		&--wehen-wiesbaden {
			background: url('../../img/png/sponsor-logos/wehen_wiesbaden-logo.png') no-repeat;
		}

		&--hallescher-logo {
			background: url('../../img/png/sponsor-logos/hallescher-logo.png') no-repeat;
		}
	}

	.footer-logos__logo-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 6px;
		max-width: 103px;
	}

	.footer-logos__description {
		color: rgba(var(--footer-text), 1);
		font: var(--small);
		font-size: 11px;
		line-height: 14px;
		text-align: center;
	}
}
