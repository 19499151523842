/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use '../utils/utility-classes';
@use '../utils/mixins';

.game-card {
	cursor: pointer;
	display: grid;
	height: 100%;
	outline: none;
	position: relative;
	scroll-snap-align: start;

	.game-card__image {
		border-radius: 3px 3px 0 0;
		height: 100%;
		min-height: 96px;
		overflow: hidden;

		img {
			cursor: pointer;
		}
	}
}

.game-card--s {
	@extend .game-card;
	@include mixins.size(346px, 72px);

	background-color: rgba(var(--gameCardWinnerBg), 1);
	border-radius: 3px;

	.game-card__image {
		border-radius: 3px 0 0 3px;
		min-height: 72px;
		overflow: hidden;
		width: 72px;
	}
}

.game-card--m {
	@extend .game-card;

	width: 96px;
	@include mixins.breakpoint(lg) {
		width: 170px;

		.game-card__image {
			height: 170px;
		}
	}

	.game-card__image {
		height: 96px;
	}
}

.game-card--l {
	@extend .game-card;
	@include mixins.size(346px, 420px);

	.game-card__image {
		height: 390px;
	}
}

.winner-details-container {
	img {
		@include mixins.size(72px);

		border-radius: 3px;
	}

	.winner-details-info {
		display: inline-block;
		height: 100%;
		overflow: hidden;
		padding: 12px;
	}
}

.game-card__hot,
.game-card__new {
	border-radius: 3px;
	font: var(--small-bold);
	padding: 0 4px;
	pointer-events: none;
	position: absolute;
	right: 4px;
	text-transform: uppercase;
	top: 4px;
	width: auto;
}

.game-card__hot {
	background-color: var(--hot-bg);
	// hot-text is not defined an every brand, so new-text is used as a fallback
	color: var(--hot-text, var(--new-text));
}

.game-card__new {
	background-color: var(--new-bg);
	color: var(--new-text);
}

.game-card__jackpot {
	align-content: center;
	align-self: center;
	background-color: var(--gameCardJackpotBg);
	border-radius: 12px;
	bottom: 42px;
	display: grid;
	justify-content: center;
	justify-self: center;
	padding: 2px 6px;
	position: absolute;
	@include mixins.breakpoint(lg) {
		padding: 4px 12px;
	}
}

.game-card__jackpot-text {
	color: var(--gameCardJackpotText);
	font: var(--small);
	@include mixins.breakpoint(lg) {
		font: var(--gameCardJackpotFont);
	}
}

.game-card__detail {
	align-items: center;
	background-color: rgba(var(--gameCardDetailBg), 1);
	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;
	min-height: 30px;
	padding: 0 0 0 8px;
	width: 100%;
}

.game-card__detail--small {
	flex: 1;
	overflow: hidden;
	padding: 12px;
}

.game-card__title {
	align-self: center;
	color: rgba(var(--gameCardTitle), 1);
	font: var(--small);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	@include mixins.breakpoint(lg) {
		font: var(--caption);
	}
}

.game-card__title--small {
	color: rgba(var(--gameCardTitleSmall), 1);
	font: var(--caption);
	overflow: hidden;
}

.game-card__title--detail {
	color: rgba(var(--gameCardTitleDetailColor), 1);
	font: var(--gameCardTitleDetailFont);
	overflow: hidden;

	span:not(:first-child) {
		padding: 0 0 0 5px;
	}
}

.game-card__detail-button {
	@include mixins.size(46px, 100%);

	align-items: center;
	display: flex;
	justify-content: center;
	min-width: 46px;

	&:focus {
		outline: 0;
	}
}

%rounded-dot {
	@include mixins.size(3px);

	background-color: rgba(var(--gameCardDotBg), 1);
	border-radius: 50%;
}

.game-card__three-dot {
	@extend %rounded-dot;

	position: relative;

	&::before {
		@extend %rounded-dot;

		content: '';
		position: absolute;
		right: 6px;
	}

	&::after {
		@extend %rounded-dot;

		content: '';
		left: 6px;
		position: absolute;
	}
}

// Live casino new game cards
.live-game-card {
	cursor: pointer;
	display: grid;
	height: 100%;
	outline: none;
	position: relative;
	scroll-snap-align: start;
}

.live-game-card--s {
	@extend .live-game-card;
	@include mixins.size(346px, 72px);

	background-color: rgba(var(--gameCardWinnerBg), 1);
	border-radius: 3px;
}

.live-game-card--m {
	@extend .live-game-card;

	width: 160px;
	@include mixins.breakpoint(lg) {
		width: 170px;
	}
}

.live-game-card--l {
	@extend .live-game-card;
	@include mixins.size(346px, 390px);
}

// WESPIN RELATED

.item--large-we-spin {
	grid-column: span 2;
	grid-row: span 6;
	max-width: 624px;
	@include mixins.breakpoint(lg) {
		max-width: 460px;
	}

	.item__image {
		height: 198px;
	}

	.item__image,
	.item__content {
		grid-column: inherit;
		width: 100%;
	}

	.item__footer {
		.item__balance-collected {
			padding: 8px 12px 6px 10px;
		}
	}

	&.tutorial-card {
		p {
			font: var(--caption);
			margin: 1px 0 20px;
		}

		.btn--light {
			width: 100%;
		}
	}

	svg {
		margin: 7px 0 0 8px;
	}
}

.display-circle {
	@include mixins.size(96px);

	margin-top: 12px;
	padding: 2px;

	img {
		@include mixins.size(92px);

		margin-bottom: 8px;
	}
}

.avatar-circle {
	@include mixins.size(100px);

	.avatar-circle__flag {
		@include mixins.size(20px);

		border-radius: 50% !important;
		bottom: -11px;
	}

	&__overlay {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		&__followers {
			align-items: center;
			display: flex;
			flex-direction: column;

			div {
				display: flex;
			}
		}
	}
}

.game--is-live {
	animation: move 1000ms infinite alternate;
	background-image: linear-gradient(90deg, #bed66b, #77a100 50%, #bed66b);
}

.game--is-bonus-hunt {
	animation: move 1000ms infinite alternate;
	background-image: linear-gradient(90deg, #8351c4, #a864ff 50%, #8351c4);
}

@keyframes move {
	to {
		background-position: 100px;
	}
}

.item__urgent {
	padding: 4px 0;
	width: 100%;
}

.game-grid-we-spin {
	display: grid;
	grid-auto-flow: dense;
	grid-gap: 12px;
	grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
	max-width: 100vw;
	@include mixins.breakpoint(lg) {
		grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
	}
	@include mixins.breakpoint(xxl) {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	}

	//@include mixins.breakpoint(md) {
	//	grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
	//}

	.ReactVirtualized__Collection {
		overflow: visible !important;
	}
}
