html {
	font-size: 62.5%;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
small,
span,
th,
caption,
td,
p,
footer {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizespeed;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	text-rendering: optimizelegibility;
}

p,
ul li,
ol li {
	font: var(--label-regular);
}

p + p {
	margin-top: 8px;
}

a {
	color: inherit;
	display: block;
	font-size: inherit;
	text-decoration: none;
}

p > a,
span > a {
	color: inherit;
	display: inline;
	font-family: inherit;
	font-style: inherit;
}

p > b {
	font-weight: bold;
}

small {
	display: block;
	font: var(--caption);

	span {
		color: inherit;
	}

	a {
		display: inline;
	}
}

.title-label,
caption {
	font: var(--label-regular);
}
