@use '../utils/mixins';

/*
.tabs-container {
  	[role='tabpanel'][aria-hidden='true'] {
		display: none;
  }
}

.is-not-logged-in {
	.tabs-bar-container {
		border-top: 0;
	}
}

.tabs-bar {
  transition: bottom 250ms ease-in;
	width: 100%;
}
*/

.tab {
	flex-basis: 0;
	flex-grow: 1;

	* {
		font: var(--caption);
	}

	a,
	button {
		display: block;
		padding: 4px 0;
	}
}

.tab a[aria-selected] {
	cursor: default;
	outline: none;
}

.counter {
	display: flex;
	height: 0;
	justify-content: center;
	right: -17px;
	top: -25px;
	z-index: 1;

	.counter__circle {
		align-items: center;
		background-color: rgba(var(--unread-message-counter-bg-color), 1);
		color: rgba(var(--unread-message-counter-text-color), 1) !important;
		display: flex;
		justify-content: center;
	}

	.counter--small {
		border-radius: 8px;
		font: var(--small-bold);
		height: 16px;
		padding: 2px 4px;
	}
}
