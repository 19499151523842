@use '../utils/mixins';

.static-text {
	font: var(--label-regular);
	@include mixins.breakpoint(lg) {
		[class^='section-head__HeadWrapper'] {
			padding-top: 56px;
		}
	}

	li {
		margin: 6px 0 12px;
	}

	b,
	p strong {
		font: var(--label-bold);
	}

	br {
		display: none;
	}

	br + br {
		display: block;
		margin-bottom: 18px;
	}

	a {
		display: inline;
		font: var(--label-regular);
		text-decoration: none;
	}

	.svg-logo {
		margin: 0 auto;
		max-width: 228px;
		width: 100%;
	}

	.page-head {
		background-color: inherit;
	}
}
