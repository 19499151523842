.info-box {
	.info-box-text {
		font: var(--caption);
	}

	.info-box-value {
		font: var(--h3);
		margin: 4px 0 12px;
	}
}
