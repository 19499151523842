@use '../utils/mixins';

/* List view */

.bonus-active {
	.offer-tag {
		border-radius: 32px 0 0 32px;
		bottom: 0;
		left: -16px;
		margin: auto;
		right: auto;
		top: 0;
	}

	.bonus-active__image-wrapper {
		width: 20.6%;
	}

	.bonus-active__image {
		top: 50%;
		transform: translateY(-50%);
	}

	.bonus-active__text-wrapper {
		padding: 8px 12px 12px;
		width: 79.4%;

		.dots-container {
			padding: 8px;

			svg {
				fill: var(--btn-dots, rgba(var(--base-11), 1));
			}
		}

		.icon--sprite {
			transform: rotate(90deg);
		}
	}

	.bonus-active__svg {
		@include mixins.size(100%, 6px);

		margin-top: 7px;

		.bonus-active__progress-lane {
			@include mixins.size(100%);
		}

		.bonus-active__progress-bar {
			height: 100%;
		}
	}

	.lottery-historical__svg-wrapper {
		background-color: var(--lotteries-cash-svg-background, rgba(var(--base-09), 1));
		height: 100%;

		svg {
			fill: var(--lotteries-cash-svg-fill, rgba(var(--base-02), 1));
		}
	}
}

/* Radial Progress Meter */

.progress {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);

	.progress__number {
		font: var(--h1);
		padding-top: 44px;
	}

	svg {
		@include mixins.size(127px, 120px);

		margin: 0 auto;

		path {
			fill: none;
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-miterlimit: 1;
			stroke-width: 10px;
			transition: stroke-dashoffset 1000ms ease-in-out;
		}

		.progress__meter {
			stroke-dasharray: 317;
			stroke-dashoffset: 317;
		}
	}
}
