// Collapse styling

.collapse-value {
	margin: 0 12px 0 auto;
}

.welcome-menu {
	.welcome-screen-collapse {
		.table-basic {
			tr,
			td {
				border-bottom: 0;
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				button {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors, max-nesting-depth
					span {
						height: 24px !important;
						position: relative;
						top: 2px;
					}
				}
			}
		}
	}
}
