.payment-tag {
	background-color: rgba(91, 92, 96, 100%);
	border-radius: 3px;
	color: rgba(255, 255, 255, 100%);
	font: var(--small-bold);
	left: 6px;
	padding: 1px 4px;
	pointer-events: none;
	position: absolute;
	text-transform: uppercase;
	top: 5px;
	width: auto;
}

.payment__fieldset-wrap-methods {
	.font-title {
		font: var(--h4-bold);
	}

	.p-tb {
		padding: 15px 12px;
	}
}
