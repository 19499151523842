@use '../utils/mixins';

.transaction-history-inactive-layer {
	height: calc(100% - 76px);
	opacity: 0.85;
	position: absolute;
	width: 100%;
}

.transaction-modal {
	@include mixins.size(100%, auto);

	position: absolute;
	z-index: 7;
}

.transaction-modal + .transaction-history__btn-wrapper {
	z-index: 8;
}

.transcation-detail {
	position: fixed;
}
