@use '../utils/mixins';

.flag {
	@include mixins.size(48px);

	background-repeat: no-repeat;

	&.flag--cl {
		background-image: url('../../img/svg/flags/flag__chile.svg');
	}

	&.flag--de {
		background-image: url('../../img/svg/flags/flag__germany.svg');
	}

	&.flag--dk {
		background-image: url('../../img/svg/flags/flag__denmark.svg');
	}

	&.flag--en {
		background-image: url('../../img/svg/flags/flag__global.svg');
	}

	&.flag--eu {
		background-image: url('../../img/svg/flags/flag__eu.svg');
	}

	&.flag--fi {
		background-image: url('../../img/svg/flags/flag__finland.svg');
	}

	&.flag--gb {
		background-image: url('../../img/svg/flags/flag__united_kingdom.svg');
	}

	&.flag--no {
		background-image: url('../../img/svg/flags/flag__norway.svg');
	}

	&.flag--pl {
		background-image: url('../../img/svg/flags/flag__poland.svg');
	}

	&.flag--se {
		background-image: url('../../img/svg/flags/flag__sweden.svg');
	}
}
