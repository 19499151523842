@use '../utils/mixins';

:root {
	--autocompleteOptionItemHeight: 44px;
}

.autocomplete {
	&__option-list {
		background-color: var(--search-results-bg);
		border-bottom: 1px solid var(--select-border-bottom-color);
		max-height: calc(var(--autocompleteOptionItemHeight) * 6);
		overflow: auto;
		top: 62px;
		width: 100%;
		z-index: 1;
	}

	&__option-item {
		min-height: var(--autocompleteOptionItemHeight);
		padding: 12px;

		&:hover,
		&_active {
			background-color: var(--search-result-item-bg);
		}
	}
}
