// Margin & Padding

$viewPad: 56px 12px 0 12px;

:root {
	--headerHeight: 64px;
	--subHeaderHeight: 48px;
	--smallerHeaderHeight: 44px;
	--smallerSubHeaderHeight: 48px;
	--unAuthHeight: 76px;
	--heroHeight: 96px;
	--regulationHeight: 44px;
	--inGameToolbarHeight: 44px;
	--inGameToolbarWidth: 36px; // only available for landscape orientation
	--inGameBottomBarHeight: 44px;
	--textWidth: 820px;
	--detailWidth: 375px;
	--transitionTime: 0.3;
	--smallerFrame: 12px;
	--biggerFrame: 24px;
	--sidebarHeaderHeight: 44px;
	--appbannerHeight: 95px;
	--modalContentMaxWidth: 744px;
	--cardImageHeight: 212px;
	--searchBarHeight: 60px;
	--campaignRegWidth: 424px;
	--credit-card-width: 285px;
	--credit-card-height: 160px;
	--casino-navbar-height--mobile: 60px;
	--casino-navbar-height--desktop: 72px;
	--gameCardBottomInfoHeight: 38px;

	//top-bar fixed heights

	// Sportsbook
	--sportsbookTopBarHeight: 60px;
	--sportsbookLoginFooterHeight: 78px;
	--sportsbookSearchTopOffsetSmall: var(--smallerHeaderHeight);
	--sportsbookBetslipHeaderWrapperMinHeight: 51px;
	--sportsbookHighlightGamesBetslipHeaderWrapperMinHeight: 51px;
}
