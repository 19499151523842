@use '../utils/mixins';

.shop-grid,
.marketplace-grid {
	display: grid;
	grid-auto-flow: dense;
	grid-auto-rows: 85px;
	grid-gap: 12px;
	grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
	padding: 56px 12px 12px;
	@include mixins.breakpoint(lg) {
		grid-auto-rows: 92px;
		grid-template-columns: repeat(auto-fill, minmax(148px, 1fr));
	}

	.item {
		grid-template-rows: 58% 1fr;
	}

	.item--voucher {
		grid-row: span 3;
		grid-template-columns: unset;
		grid-template-rows: max-content;

		.btn-voucher-code {
			transform: translate3d(0, 0, 0);
		}
	}
}

.marketplace-grid__promotions {
	grid-auto-rows: minmax(85px, auto);
	@include mixins.breakpoint(lg) {
		grid-auto-rows: minmax(80px, auto);
	}
}

.shop-item-img {
	picture {
		img {
			border-radius: 6px;
		}
	}
}

.shop-tier-img {
	img {
		border-radius: 6px;
	}
}

.points {
	@include mixins.size(64px);

	.points__svg {
		border-top-left-radius: 3px;
		z-index: 1;

		text {
			font: var(--caption);
		}
	}
}

.offer-tag {
	@include mixins.size(32px);

	bottom: -16px;
	z-index: 1;

	svg {
		@include mixins.size(16px);
	}
}

.ended-tag {
	@include mixins.size(64px);

	.points__svg text {
		font: var(--label-regular);
	}
}
