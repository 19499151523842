@use 'sass:list';
@use '../utils/mixins';
@use '../utils/z-index';

.result {
	@include mixins.size(100%);

	backface-visibility: hidden;
	background-size: 100% 200%;
	left: 0;
	position: absolute;
	top: 0;
	transform: translate3d(0, 100%, 0);
	transition: transform 250ms ease-out, background-position 250ms 500ms ease-out;
	z-index: list.index(z-index.$elements, modal-view);
	@supports not (-webkit-touch-callout: none) {
		/* CSS for other than iOS devices */
		overflow-y: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		.payment-result-wrapper {
			height: calc(100% - var(--sidebarHeaderHeight));
			overflow-y: scroll;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&.result--is-open {
		background-position: 0 100%;
		opacity: 1;
		transform: translate3d(0, 0, 0);
		visibility: visible;

		.scroll {
			@supports not (-webkit-touch-callout: none) {
				overflow-x: hidden;
				overflow-y: auto;
			}
		}

		.failed-deposit .scroll {
			@media screen and (max-width: 650px) {
				min-height: 60vh;
			}
			@supports not (-webkit-touch-callout: none) {
				padding-bottom: 70px;
			}
		}

		h3 {
			margin-bottom: 7px;
			overflow: hidden;
		}

		.btn--secondary {
			background-color: rgba(var(--base-11), 0.25);
			overflow: hidden;
		}
		@media (min-height: 1220px) {
			.failed-deposit {
				height: 80%;

				section[data-at='payment-result-header'] {
					height: 100%;
				}
			}
		}

		.failed-deposit {
			.js-notify__button-wrapper--failed.u-btn-wrapper {
				background-color: var(--sidebar-bg);
			}
		}

		.js-try-again-button-header {
			cursor: pointer;
			font: var(--label-regular);
			margin: auto 12px auto auto;
		}

		.failed {
			.u-btn-wrapper {
				background-color: var(--sidebar-bg);
			}
		}
	}
}

.jsx-payment {
	.js-try-again-button-header {
		cursor: pointer;
		font: var(--label-regular);
		margin: auto 12px auto auto;
	}
}

.u-message,
.u-result-header {
	font: var(--label-regular);
}

.u-result {
	font: var(--h2);
	margin: 2px 0 28px;
}

div[class^='action-screen'] {
	background-color: var(--sidebar-bg, rgba(var(--success), 1));
}
