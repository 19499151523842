.signup {
	display: none;
	height: 100%;

	.signup__content {
		padding: 0 0 12px;
	}

	.signup-step {
		-webkit-overflow-scrolling: touch;
		transition: transform 250ms;
	}

	&__form {
		opacity: 0;
		visibility: hidden;
	}

	.slide-out-down {
		transform: translate3d(0, 100vh, 0);
	}

	.slide-in-up {
		transform: translate3d(0, 0, 0);
	}

	.disclaimer-text {
		text-decoration: underline;
	}

	.input-wrap__checkbox {
		left: -9999px;
		max-height: none;
		opacity: 0;
		position: absolute;
	}

	.input-wrap,
	.custom-select {
		margin-right: 12px;
	}
}

.signup--open {
	.disclaimer-text {
		text-align: left;

		&.disclaimer-text--pzbuk {
			font: var(--caption);
			padding-left: 28px;
		}

		&.button-link-text {
			text-align: center;
		}
	}
}

.bottom-wrapper {
	&.signup--open {
		top: 0;
		transform: translate3d(0, 0, 0);
		width: 100%;

		.signup {
			transform: scaleY(1);
		}

		.signup__form {
			opacity: 1;
			visibility: visible;
		}

		.btn-container {
			animation: bounceOutRight 250ms forwards;
			position: absolute;
		}
	}

	&.log-in--open,
	&.signup--open {
		.page-head {
			background-color: var(--sidebar-bg);
		}
	}
}

.user-options.user-options--register ~ .filters-container {
	.signup-step {
		.signup__content {
			padding: 6px 0 12px 12px;
		}
	}
}
