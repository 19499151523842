@use '../utils/mixins';

.main-title {
	font: var(--label-bold);

	&.main-title--large {
		font: var(--h2);
	}
}

.support-title {
	margin-bottom: 2px;
	position: relative;
	z-index: 1;
	@include mixins.breakpoint(sm) {
		font: var(--small);
	}
	@include mixins.breakpoint(lg) {
		font: var(--caption);
	}

	&.support-title--medium {
		font: var(--caption);
	}

	&.support-title--large {
		font: var(--label-bold);
	}
}

.info-text {
	font: var(--label-regular);
}

.disclaimer-text {
	font: var(--label-regular);
}

.block-dividing-header {
	margin: 32px 0 16px;
}
