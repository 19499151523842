.desktop-in-game {
	max-width: 1280px;
	width: 100%;
}

.ingame-wrapper {
	margin-top: 76px;

	object,
	iframe {
		height: 73vh;
		max-width: 1280px;
	}
}

.ingame-wrapper-with-regulation-header {
	margin-top: 120px;

	object,
	iframe {
		height: 73vh;
		max-width: 1280px;
	}
}

.logoMGA,
.logoAlderney,
.logoCuracao,
.logoDGA {
	height: 24px;
}

.logoGamblingCommission {
	background: url('../../img/png/sprite-footer.png') no-repeat;
	background-position: 0 -200px;
	height: 32px;
	width: 96px;
}
