@use '../utils/mixins';

.progress {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);

	.progress__number {
		font: var(--h1);
		padding-top: 44px;
	}

	svg {
		@include mixins.size(127px, 120px);

		margin: 0 auto;

		path {
			fill: none;
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-miterlimit: 1;
			stroke-width: 10px;
			transition: stroke-dashoffset 1000ms ease-in-out;

			&.risk-meter-base {
				stroke: rgba(var(--risk-level-base), 1);
			}
		}

		.progress__meter {
			stroke-dasharray: 317;
			stroke-dashoffset: 317;
		}

		.risk-meter {
			&.progress__meter-na {
				stroke: rgba(var(--risk-level-base), 1);
			}

			&.progress__meter-1 {
				stroke: rgba(var(--risk-level-1), 1);
			}

			&.progress__meter-2 {
				stroke: rgba(var(--risk-level-2), 1);
			}

			&.progress__meter-3 {
				stroke: rgba(var(--risk-level-3), 1);
			}

			&.progress__meter-4 {
				stroke: rgba(var(--risk-level-4), 1);
			}
		}
	}

	.meter__title {
		font: var(--caption);
		padding-top: 44px;
	}

	.meter__content {
		font: var(--label-bold);
		padding-top: 60px;
	}
}
