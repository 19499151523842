@use 'sass:list';
@use '../utils/mixins';
@use '../utils/z-index';

.shop-detail {
	@include mixins.size(100%);

	left: 0;
	overflow-y: auto;
	position: absolute;
	top: 0;
	transform: translate3d(0, 100%, 0);
	transition: transform 250ms ease-out;
	z-index: list.index(z-index.$elements, modal-view);
}

.shop-detail--open {
	backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	transform: translate3d(0, 0, 0);
}

.shop-detail__description {
	padding: 37px 12px;

	.main-title {
		margin-bottom: 8px;
		word-break: break-word;
	}
}

// Free Money Bonus Detail

.shop-detail,
.shop-detail-sidebar {
	height: auto;

	.points {
		@include mixins.size(96px);

		.points__svg {
			border-top-left-radius: 0;

			text {
				font: var(--h4-bold);
			}
		}
	}
}

.shop-detail {
	height: 100%;
}
