@use 'sass:list';
@use '../utils/mixins';
@use '../utils/z-index';

.quick-deposit-wrapper {
	@include mixins.size(212px, 73vh);

	min-width: 212px;
	opacity: 0;
	overflow: hidden;
	transition: opacity 300ms ease-in;

	&.fadein {
		opacity: 1;
	}

	.page-head {
		z-index: 2;
	}

	.page-head__title {
		display: none;
	}

	.page-head__left-side {
		margin: 0;
		padding: 12px 0 0 12px;
	}

	.notify__head {
		padding-bottom: 48px;
	}

	.notify__main-title {
		padding: 0;
	}

	&.quick-deposit-wrapper--is-closed {
		min-width: initial;
		width: 44px;

		.page-head {
			height: 100%;
		}

		.page-head__left-side {
			margin: 0;
			padding: 9px 0 8px 9px;
			position: absolute;

			.page-head__back {
				transform: rotate(180deg);
				transition: transform 250ms;
			}
		}

		.page-head__title {
			display: block;
			left: 34px;
			position: absolute;
			top: 44px;
			transform: rotate(90deg);
			transform-origin: left top 0;
		}

		.quick-deposit-content {
			display: none;
		}
	}
	// FIRST TIME SCREEN
	.u-btn-wrapper {
		width: 100%;
	}
}

// USER FEEDBACK

.quick-deposit-response {
	@include mixins.size(100%);

	z-index: list.index(z-index.$elements, modal-view);

	&.quick-deposit-response--success {
		background-color: rgba(var(--success), 1);
	}

	&.quick-deposit-response--error {
		background-color: rgba(var(--error), 1);
	}
}
