@use '../utils/mixins';

.search-results__item {
	align-items: center;
	background: var(--search-result-item-bg);
	display: grid;
	grid-template-columns: auto 30px;
	height: 52px;
}

.search-results__item.secondary-bg {
	background: var(--search-result-item-bg-secondary);
}

@keyframes phAnimation {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

.search-results__figure {
	display: grid;
	grid-template-columns: 60px auto;
	grid-template-rows: 52px;
}

.search-results__caption {
	color: var(--search-results-caption);
	font: var(--search-results-caption-font);
	padding: 0 0 0 12px;

	> span {
		color: var(--search-results-caption);
	}
}

.search-icon {
	align-items: center;
	background-color: var(--search-icon-bg-color);
	border-radius: 12px;
	display: flex;
	flex-shrink: 0;
	height: 36px;
	justify-content: center;
	text-align: center;
	width: 36px;
	z-index: 1;
	@include mixins.breakpoint(lg) {
		background-color: transparent;
		position: absolute;
	}

	&.search-icon-active {
		background-color: transparent;
		position: absolute;
	}

	svg {
		@include mixins.size(20px);

		fill: var(--search-icon);
		transition: fill 200ms ease-out;
	}
}

.search-icon--right {
	bottom: auto;
	left: auto;
	right: 0;
	top: 0;

	&.search-icon--desktop {
		right: 4px;
	}
}

.search-icon--open {
	left: 8px;
}

.search-icon--desktop {
	&.search-icon--open {
		left: 0;
	}

	svg {
		fill: var(--search-icon-large);
	}
}
