@use 'sass:list';
@use '../utils/z-index';
@use '../utils/mixins';

.btn {
	border-radius: var(--brand-border-radius, 3px);
	display: inline-block;
	font: var(--h3);
	padding: 14px;
	text-align: center;
	transition: transform 250ms ease-out, color 250ms ease-out;
	white-space: nowrap;
	width: 100%;

	&.btn--skip,
	&.btn--clear,
	&.btn--login,
	&.btn--signup {
		background: transparent;
		border: 0;
		font: var(--label-regular);
		outline: none;
		padding: 12px 0;
		text-decoration: none;
		width: auto;
		z-index: list.index(z-index.$elements, header-icon);
	}

	&.btn--ghost {
		background: transparent;
		border: 1px solid rgba(var(--base-01), 1);
		font: var(--label-bold);
		padding: 5px 18px;
	}

	&.btn--transparent {
		background: transparent;
		border-top: 1px solid rgba(var(--base-02), 1);
		font: var(--label-regular);
		padding: 16px;

		&:disabled {
			color: rgba(var(--base-05), 1);
		}
	}

	&.btn--secondary {
		&:disabled {
			color: rgba(var(--base-05), 1);
		}
	}

	&.btn--tertiary {
		font: var(--label-regular);
	}

	&.btn--round {
		border-radius: 26px;
	}

	&.btn--small {
		font: var(--label-bold);
		padding: 8px;
	}

	&.btn--auto {
		padding: 8px 24px;
		width: auto;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.btn--is-loading {
		color: rgba(var(--base-02), 0) !important;
	}

	&.btn--applepay {
		/* Safari only */
		appearance: -apple-pay-button;
		padding: 26px;
		-apple-pay-button-type: plain;
	}

	&::first-letter {
		text-transform: uppercase;
	}

	&.btn--link {
		background-color: transparent;
		border: 0;
		font: var(--label-regular);
		padding: 0;
		text-decoration: underline;
	}

	&.btn--first {
		border-radius: 3px 0 0 3px;
	}

	&.btn--last {
		border-radius: 0 3px 3px 0;
		margin-left: 1px;
	}

	&.btn--upload-secondary {
		text-align: left;

		svg {
			right: 8px;
			top: 14px;
		}
	}

	&.js-verify-get-code {
		/* .icon--sprite {
			left: 4px;
			position: relative;
			top: 2px;
		} */
	}

	&:focus-visible {
		outline: -webkit-focus-ring-color auto 1px;
	}

	&.btn--clear-all {
		background: transparent;

		&:disabled {
			background: transparent !important;
			color: var(--button-disabled-text);
		}
	}

	&.btn-send-mail-disabled,
	&.js-verify-get-code {
		align-items: center;
		display: flex;
		justify-content: center;
	}
}

.call-support-btn {
	background-color: rgba(var(--base-11), 1);
	border-radius: 24px;
	color: rgba(var(--base-02), 1);
	font: var(--label-regular);
	padding: 12px 24px;
	z-index: list.index(z-index.$elements, support-button);

	svg {
		margin-left: 6px;
	}
}

.u-btn-wrapper {
	/* Sometimes we have a group of secondary buttons, then the following styles apply. Note this only works for 1-3 buttons */
	width: 100%;

	.btn--secondary {
		border-radius: 0;
		margin-bottom: 1px;
		position: relative;
	}

	.btn--secondary.js-support-button {
		margin-bottom: 12px;
	}

	> .btn--secondary:nth-child(2) {
		border-radius: 3px 3px 0 0;
	}

	> .btn--secondary:nth-last-child(2) {
		border-radius: 0 0 3px 3px;
	}

	> .btn--secondary:last-child {
		border-radius: 3px;
		margin-top: 8px;
	}

	.btn--secondary:first-child {
		border-radius: 3px 3px 0 0;
	}

	.btn--secondary:only-child {
		border-radius: 3px;
		margin: 0;
	}

	&.js-notify__button-wrapper--failed {
		.btn--secondary:first-child {
			border-radius: 3px;
		}
	}

	&.u-btn-wrapper--is-hidden {
		transform: translate3d(0, 100%, 0);
	}
}

.buy-in-buttons {
	padding-bottom: 5px;

	.btn {
		border-radius: 18px;
		flex: 1 1 0;
		margin: 10px 0 0;
		padding: 7px 10px;

		&:focus {
			outline: none;
		}

		&:disabled {
			background-color: var(--button-disabled-bg);
			color: var(--button-disabled-text);
		}
	}
}

.btn-rounded {
	background-color: var(--button-primary-bg, rgba(var(--primary), 1));
	border-radius: 26px;
	color: var(--button-primary-text, rgba(var(--base-02), 1));
	font: var(--label-bold);
	padding: 11px 40px;
	position: relative;
	text-align: center;
	white-space: nowrap;
	width: auto;

	&:focus {
		outline: none;
	}

	&:disabled {
		background-color: var(--button-disabled-bg);
		color: var(--button-disabled-text);
		cursor: not-allowed;
	}
}

.upload-file--button {
	input[type='file'] {
		@include mixins.size(0.1px);

		opacity: 0;
		position: absolute;
	}
}

.button__large {
	background: rgba(var(--buttonLargeBg), 1);
	border-radius: 26px;
	color: rgba(var(--buttonLargeText), 1);
	cursor: pointer;
	font: var(--h4-bold);
	outline: none;
	padding: 14px 45px;

	&:hover {
		color: rgba(var(--buttonLargeTextHover), 1);
	}
}

.btn-rounded-small {
	font: var(--caption);
	padding: 10px;
}

.button-wrapper {
	.btn {
		margin-bottom: 1px;
	}
}

.create-events-button,
.wespin-upload-file--button {
	padding: 8px 24px;

	&:hover {
		@media (hover: hover) {
			cursor: pointer;
		}
	}
}
