@use '../utils/mixins';

// EXPLORE CATEGORY CAROUSEL
.carousel__wrapper {
	align-content: center;
	display: grid;
	height: auto;
	overflow: hidden;
	padding-left: 4px;
	position: relative;
	width: auto;
}

.carousel__scroller {
	display: grid;
	grid-auto-flow: column;
	grid-gap: 6px;
	justify-content: flex-start;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	padding: 4px 0;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
	width: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	&::after {
		content: '';
		right: 0;
		width: 20px;

		@include mixins.breakpoint(lg) {
			width: 25px;
		}
	}
}

.carousel__arrow {
	align-content: center;
	align-self: center;
	background: rgba(var(--category-arrow-bg), 1);
	cursor: pointer;
	display: grid;
	height: 42px;
	justify-content: flex-start;
	outline: none;
	position: absolute;
	width: 21px;
	z-index: 1;

	&::after {
		background: transparent;
		border-radius: 50%;
		bottom: -50%;
		content: '';
		height: 80px;
		position: absolute;
		width: 80px;
	}

	.icon--sprite {
		fill: var(--category-arrow-icon-color, rgb(var(--base-02)));
		height: 20px;
		position: relative;
		width: 20px;
	}
}

.carousel__arrow--left {
	border-bottom-right-radius: 42px;
	border-top-right-radius: 42px;
	left: 4px;

	.icon--sprite {
		left: -2px;
	}

	&::after {
		left: -50px;

		@include mixins.breakpoint(lg) {
			left: -35px;
		}
	}
}

.carousel__arrow--right {
	border-bottom-left-radius: 42px;
	border-top-left-radius: 42px;
	right: 0;

	.icon--sprite {
		right: -3px;
	}

	&::after {
		right: -35px;

		@include mixins.breakpoint(lg) {
			left: -30px;
		}
	}
}
