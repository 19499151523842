@use '../utils/mixins';

.event-type-title-bg {
	align-content: center;
	align-items: center;
	border-radius: 3px 0 0 3px;
	display: flex;
	height: 100%;
	justify-content: center;
	text-align: center;
	width: 100%;

	.event-type-title-text {
		background-clip: text;
		background-size: cover;
		color: transparent;
		font-size: 13px;
		letter-spacing: -1px;
		text-align: center;
		text-transform: uppercase;
		word-spacing: 50px;
	}
}

.top-of-page-image-wrapper {
	// top: -44px;
	min-height: 154px;
	// .stream-element-wrapper {
	// 	margin-top: 44px;
	// }
	.fade-image {
		width: 100%;

		img {
			min-height: 198px;
			position: absolute;
			top: -44px;
		}

		&::after {
			@include mixins.size(100%);

			content: '';
			left: 0;
			min-height: 198px;
			position: absolute;
			top: -44px;
		}

		.event-type-title-bg {
			max-height: 195px;
			min-height: 195px;
			position: absolute;
			top: -44px;
			width: 375px;

			.event-type-title-text {
				font-size: 60px;
				line-height: 60px;
			}
		}
	}

	.stream-element-wrapper {
		.wespin-streamelement {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			min-height: 154px;
		}

		.wespin-streamer-description {
			width: 280px !important;
		}
	}
}

.game-view__iframe > .stream-element-wrapper {
	visibility: hidden;
}

.info-box,
.radio-box {
	padding: 24px 0 12px;

	button {
		margin: 4px 0;
		// width: 100%;
	}

	small {
		margin-top: 8px;
	}
}

.radio-box {
	small {
		font: var(--label-regular);
	}

	.custom .custom-right-radio {
		h5,
		p {
			margin-bottom: 6px;
		}
	}
}

.more {
	.bonus-active {
		margin: 4px 0 6px;
	}
}

.stream-name-description-wrapper {
	padding: 20px 12px 5px;
}

.streamer-presentation {
	padding-bottom: 18px;
}

.display-circle--svg {
	@include mixins.size(108px);

	margin-top: 12px;
	padding: 24px 24px 0;

	svg {
		@include mixins.size(56px);

		margin: 0 auto;
	}
}

.game-view {
	.game-view__side {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 282px;
	}
}

.chat-iframe-container {
	iframe {
		height: auto;
	}
}

.form-header {
	margin: 21px 0 4px;
}

.form {
	.custom-select--label {
		font: var(--h4-regular);
		left: 12px;
		position: absolute;
		top: 2px;
		transform: scale(0.75);
		transform-origin: left;
	}

	.custom-select {
		border-bottom: 0;
	}
}

.new {
	border-radius: 3px;
	font: var(--caption);
	padding: 2px 4px;
	position: absolute;

	&.new-tag-product-nav {
		right: -26px;
		top: -4px;
	}

	&.new-global-nav {
		left: 64px;
		top: -4px;
	}

	&.new-landing-page {
		margin: -8px 0 0 -16px;
	}
}

.chevron-round {
	align-items: center;
	border-radius: 50%;
	display: flex;
	height: 36px;
	justify-content: center;
	width: 36px;
}

.BONUS_HUNT,
.BONUS_BUY,
.JACKPOT_HUNT,
.STREAMERS_CHOICE {
	animation: move 4000ms infinite alternate;
}

@keyframes move {
	25% {
		background-position: 50px -50px;
	}

	50% {
		background-position: 0 50px;
	}

	75% {
		background-position: 100px 0;
	}

	100% {
		background-position: 0 -100px;
	}
}

// Wespin live dashboard countdown fix making it just one row
.live-dashboard-item {
	h2 {
		margin: 0 !important;

		span {
			margin: 2px !important;
			padding: 0 !important;
			word-spacing: -3px !important;
		}
	}
}

.basic-horizontal-card-wrapper {
	div {
		// &.basic-horizontal-card--top-text {
		// 	margin-top: ;
		// }
		&.basic-horizontal-card--middle-text,
		&.basic-horizontal-card--bottom-text {
			margin-top: 2px;
		}

		&.basic-horizontal-card--bottom-tag {
			margin-top: 4px;
		}
	}
}

.buy-in-buttons {
	justify-content: space-evenly;
	width: 100%;

	div {
		flex-direction: column;
		justify-content: center;
		margin: 0 6px;
		width: 112px;
	}
}

.info-box.edit-buy-in {
	align-items: center;
	display: flex;
	flex-direction: column;

	.edit-buyin-button {
		padding: 7px 24px;
	}
}

.item--large-we-spin {
	.btn.btn--small.btn--clip-card {
		padding: 7px 24px;
	}

	.btn--tutorial-card-button {
		#tutorial-card-link {
			font: var(--label-regular);
			margin: 0;
		}
	}
}
