// Rules only applies to DepositBeta - Select A Bonus view

/*
.bonus-item__image-wrapper {
  flex: 1;
  position: relative;
  .bonus-item__image {
		height: 288px;
  }
  .bonus-item__addon,
  .bonus-item__image {
		margin: auto;
	}
  .bonus-item__addon {
		@include breakpoint(sm) {
	  	@include size(120px);
		}
		@include breakpoint(lg) {
	  	@include size(180px);
		}
		@include breakpoint(xl) {
	  	@include size(250px);
		}
	}
}

.multichoice-list {
  input[type=radio] {
    + svg {
	  	@include size(24px);
	  	display: none;
		}
		&:checked {
	  	+ svg {
	  	  display: block;
	  	}
		}
  }
}

.static-text.deposit-limits-banner {
  background-color: rgba(var(--info), 1);
  .page-head {
    background-color: inherit;
  }
}

*/

// Rules only applies for DepositBeta Bonus Slider View

.deposit {
	.deposit-bonus {
		.payment__content-wrapper {
			padding: 56px 0 0;
		}
	}
}

.logo-payment-method {
	background-color: rgba(var(--base-01), 1);
	border-radius: 3px;
}

.swish-logo {
	.cls-2,
	.cls-6 {
		mix-blend-mode: multiply;
	}

	.cls-5 {
		clip-path: url(#clip-path);
	}

	.cls-6,
	.cls-9 {
		opacity: 0.4;
	}

	.cls-6 {
		fill: url(#linear-gradient-2);
	}
}

.deposit-bonus-in-sidebar {
	.learn-more-btn {
		font: var(--label-bold);
		text-transform: none;
	}
}

.deposit-limit {
	.input-wrap__label {
		left: 0;
	}
}

.SWISH .qr-code {
	margin-top: -10px;
	padding-bottom: 10px;
	padding-top: 0;
}

.swish-loader {
	align-items: center;
	color: var(--swish-loader-dots);
	display: flex;
	flex-direction: column;
	height: 20px;
	margin-top: 5px;
}
